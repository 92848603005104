import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

import { Headers } from 'src/app/core/constants/http.constants';
import { HttpUtils, IHttpOptions } from 'src/app/core/utils/http.utils';
import {
  GridToolbarConfig,
  GridToolbarDropdown,
  GridToolbarSwitch,
} from '../../components/base/grid/components/grid-toolbar/entities/grid-toolbar-config';
import { Endpoints } from '../../constants/endpoints.constants';
import {
  FileExporterFileNames,
  FileExporterType,
} from '../../constants/file-exporter.constants';
import { FileExportRequestPayload } from '../entities/file-export-request-payload';
import { SelectedFilters } from '../entities/filters/selected-filters';
import { OpportunityResponseItem } from '../entities/grids/opportunities-response';
import { RequestPayload } from '../entities/request-payload';
import { ComponentNames } from 'src/app/modules/ieopportunity/actualsales/components/actualsales-grid/constants/actualsales-grid.constants';
import { Customer } from '../entities/filters/customer';

import { FileExporterService } from '../file-exporter.service';

@Injectable({
  providedIn: 'root',
})
export class OpportunitiesGridService {
  constructor(
    private http: HttpClient,
    private fileExporterService: FileExporterService
  ) {}

  getOpportunities(
    request: RequestPayload
  ): Promise<Array<OpportunityResponseItem>> {
    const options: IHttpOptions = HttpUtils.getOptions([
      Headers.CLIENTAPPID_HEADER,
      Headers.IMPERSONATION_USER_HEADER,
      Headers.CORRELATION_ID_HEADER,
    ]);

    return this.http
      .post<{ value: Array<OpportunityResponseItem> }>(
        Endpoints.opportunities,
        request,
        options
      )
      .pipe(
        switchMap((x: { value: Array<OpportunityResponseItem> }) => {
          return of(
            x.value.map(
              (y: OpportunityResponseItem) => new OpportunityResponseItem(y)
            )
          );
        }),
        catchError(this.errorHandler.bind(this))
      )
      .toPromise();
  }

  saveOpportunities(
    opportunities: Array<OpportunityResponseItem>,
    customer: Customer
  ): Promise<Array<OpportunityResponseItem>> {
    const options: IHttpOptions = HttpUtils.getOptions([
      Headers.CLIENTAPPID_HEADER,
      Headers.IMPERSONATION_USER_HEADER,
      Headers.CORRELATION_ID_HEADER,
    ]);

    const oppsToSave: Array<Record<string, any>> = opportunities.map(
      (x: OpportunityResponseItem) => {
        return {
          id: x.Id,
          name: x.Name,
          projection: x.ProjectionPct,
          masterCustomerNumber: customer.MasterCustomerNumber,
          masterCustomerName: customer.MasterCustomerName,
        };
      }
    );

    return this.http
      .put<Array<OpportunityResponseItem>>(
        Endpoints.opportunitiesSave,
        oppsToSave,
        options
      )
      .pipe(catchError(this.errorHandler.bind(this)))
      .toPromise();
  }

  export(
    selectedFilters: SelectedFilters,
    toolbarConfig: GridToolbarConfig,
    breakdowns: Record<string, boolean>
  ): Promise<boolean> {
    const targetsDropdown: GridToolbarDropdown = toolbarConfig.findControl(
      ComponentNames.targets
    );
    const noActivitySwitch: GridToolbarSwitch = toolbarConfig.findControl(
      ComponentNames.noActivity
    );

    const extraParams: Record<string, any> = {
      breakdownSG: breakdowns.isSgBreakdown,
      breakdownWMU: breakdowns.isWmuBreakdown,
      period: '',
      target: targetsDropdown.selected.value,
      noActivity: noActivitySwitch.switchValue, // Exclude No Activity
      stage: 'opportunity',
    };

    const payload: FileExportRequestPayload =
      FileExportRequestPayload.createRequest(selectedFilters, extraParams);

    const fileName: string =
      breakdowns.isSgBreakdown && breakdowns.isWmuBreakdown
        ? FileExporterFileNames.OpportunitySgWmu
        : breakdowns.isSgBreakdown
        ? FileExporterFileNames.OpportunitySg
        : breakdowns.isWmuBreakdown
        ? FileExporterFileNames.OpportunityWmu
        : FileExporterFileNames.Opportunity;

    return this.fileExporterService.export(
      FileExporterType.opportunities,
      payload,
      fileName
    );
  }

  private errorHandler(error: HttpErrorResponse): Observable<never> {
    return throwError(error || 'Server error (OpportunitiesGridService)');
  }
}
