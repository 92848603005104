import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { HttpUtils, IHttpOptions } from 'src/app/core/utils/http.utils';
import { Observable, of, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

import { RequestPayload } from '../entities/request-payload';
import { Headers } from 'src/app/core/constants/http.constants';
import { Endpoints } from '../../constants/endpoints.constants';
import { OpportunityPhasing } from '../../components/opportunity-phasing-modal/entities/opportunity-phasing';

import { OpportunityPhasingModalConfig } from '../../components/opportunity-phasing-modal/entities/opportunity-phasing-modal-config';
import { PhasingSaveRequest } from '../../components/opportunity-phasing-modal/entities/opportunity-phasing-save';

@Injectable({
  providedIn: 'root',
})
export class OpportunityPhasingModalService {
  private openOppPhasingModalEvent =
    new EventEmitter<OpportunityPhasingModalConfig>();

  public get openOppPhasingModalEmitted(): EventEmitter<OpportunityPhasingModalConfig> {
    return this.openOppPhasingModalEvent;
  }

  constructor(private http: HttpClient) {}

  getOpportunityById(request: RequestPayload): Promise<OpportunityPhasing> {
    const options: IHttpOptions = HttpUtils.getOptions([
      Headers.CLIENTAPPID_HEADER,
      Headers.IMPERSONATION_USER_HEADER,
      Headers.CORRELATION_ID_HEADER,
    ]);

    return this.http
      .post<OpportunityPhasing>(Endpoints.phasingOpportunity, request, options)
      .pipe(catchError(this.errorHandler.bind(this)))
      .toPromise();
  }

  saveOpportunity(
    requests: Array<PhasingSaveRequest>
  ): Promise<Array<boolean>> {
    const options: IHttpOptions = HttpUtils.getOptions([
      Headers.CLIENTAPPID_HEADER,
      Headers.IMPERSONATION_USER_HEADER,
      Headers.CORRELATION_ID_HEADER,
    ]);

    return Promise.all(
      requests.map((x: PhasingSaveRequest) => {
        return this.http
          .post<OpportunityPhasing>(
            Endpoints.phasingOpportunitySave,
            x,
            options
          )
          .pipe(
            switchMap((x: Record<string, any>) => {
              return of(x.isSuccess);
            }),
            catchError(this.errorHandler.bind(this))
          )
          .toPromise();
      })
    );
  }

  private errorHandler(error: HttpErrorResponse): Observable<never> {
    return throwError(error || 'Server error (OpportunityPhasingModalService)');
  }
}
