<div class="tile card">
    <div class="tile-content card-body">
        <div class="tile-header">
            <div class="tile-title">{{gilcTile ? gilcTile.title : tmpTitle}}</div>
            <div class="tile-subtitle">{{gilcTile?.subtitle}}</div>
        </div>
        <div *ngIf="loaded && !hasErrors" class="tile-value mt-4" class="{{'char-' + (getTotal()?.getValue().length + symbol?.length)}}"
            [ngClass]="{'negative' : getTotal()?.isNegative() && getTotal()?.negativeStyleEnabled, 'positive' : getTotal()?.isPositive() && getTotal()?.positiveStyleEnabled}">
            {{getTotal()?.getValue()}}
            <span *ngIf="symbol" class="symbol">{{symbol}}</span>
        </div>
        <div *ngIf="loaded" class="tile-items">
            <div *ngFor="let item of gilcTile?.items" class="tile-item">
                <div class="item-title">
                    {{item.title}}
                </div>
                <div class="item-value" class="{{'item-char-' + item.getValue().length}}"
                    [ngClass]="{'negative' : item.isNegative() && item.negativeStyleEnabled, 'positive' : item.isPositive() && item.positiveStyleEnabled}">
                    {{item.getValue()}}
                </div>
            </div>
        </div>
        <div *ngIf="!loaded" class="tile-items">
            <div *ngFor="let item of tmpItems" class="tile-item">
                <div class="item-title">
                    {{item}}
                </div>
                <div class="item-value">
                    0
                </div>
            </div>
        </div>
        <div *ngIf="loaded && hasErrors" class="tile-error text-center">
            <span class="tile-error-header">Sorry!</span>
            <span class="tile-error-body d-block">{{errorMessage}}</span>
        </div>
        <app-spinner name="gilc-tile-spinner-{{type}}" [showSpinner]="!loaded" [fullScreen]="false"
            spinnerColor="#3e4851">
        </app-spinner>
    </div>
</div>