import { Component } from '@angular/core';
import { Subject, Subscription } from 'rxjs';

import { MetricType } from 'src/app/shared/constants/metrics.constants';
import { AppStateService } from 'src/app/shared/services/app-state.service';
import { AppState } from 'src/app/shared/services/entities/app-state/app-state';
import { TextValuePair } from 'src/app/shared/services/entities/common/key-value';

import { GilcTileService } from 'src/app/shared/services/tiles/gilc-tile.service';
import { ProjectionTileService } from 'src/app/shared/services/tiles/projection-tile.service';
import { TargetTileService } from 'src/app/shared/services/tiles/target-tile.service';
import { TrendTileService } from 'src/app/shared/services/tiles/trend-tile.service';

@Component({
  selector: 'app-speculative',
  templateUrl: './speculative.component.html',
  styleUrls: ['./speculative.component.scss'],
})
export class SpeculativeComponent {
  openTrendModalEmittedEvent = new Subject<boolean>();
  isGridExpanded: boolean;

  subscription = new Subscription();
  showGcpComponents: boolean;

  public get metricType(): typeof MetricType {
    return MetricType;
  }

  constructor(
    private targetTileService: TargetTileService,
    private trendTileService: TrendTileService,
    private projectionTileService: ProjectionTileService,
    private gilcTileService: GilcTileService,
    private appStateService: AppStateService
  ) {}

  ngOnInit(): void {
    this.subscription.add(
      this.appStateService.appStateChanged.subscribe((x: AppState) => {
        this.showGcpComponents = x.showGcpComponents;
      })
    );
  }

  onOpenTrendModalEmitted(isChartView: boolean, type: MetricType): void {
    this.openTrendModalEmittedEvent.next(isChartView);
  }

  onMetricChanged(selected: TextValuePair): void {
    this.targetTileService.metricChanged.next(selected);
    this.trendTileService.metricChanged.next(selected);
    this.projectionTileService.metricChanged.next(selected);
    this.gilcTileService.metricChanged.next(selected);
  }
}
