import { ErrorHandler, NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER } from '@angular/core';
import { MsalRedirectComponent, MsalModule } from '@azure/msal-angular';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppConfigService } from './core/services/app-config.service';
import { RebarAuthModule } from './core/rebarauth/rebar.auth.module';
import { VirtualScrollerModule } from 'ngx-virtual-scroller';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ChartsModule } from 'ng2-charts';
import { AgGridModule } from 'ag-grid-angular';
import { ClipboardModule } from 'ngx-clipboard';
import 'ag-grid-enterprise';

// Notification Framework
import { UniversalHeaderComponent } from './shared/components/notification-framework/universal-header/universal-header.component';
import { ModalComponent } from './shared/components/notification-framework/modal/modal.component';
import { AlertComponent } from './shared/components/notification-framework/alert/alert.component';
import { ToastComponent } from './shared/components/notification-framework/toast/toast.component';
import { ChatComponent } from './shared/components/notification-framework/chat/chat.component';

// Modules
import { OverviewComponent } from './modules/overview/overview.component';
import { IeopportunityComponent } from './modules/ieopportunity/ieopportunity.component';
import { PhasingComponent } from './modules/phasing/phasing.component';
import { SpeculativeComponent } from './modules/speculative/speculative.component';
import { BacklogComponent } from './modules/backlog/backlog.component';
import { AnalyticsComponent } from './modules/analytics/analytics.component';
import { OpportunitiesComponent } from './modules/ieopportunity/opportunities/opportunities.component';
import { ActualSalesComponent } from './modules/ieopportunity/actualsales/actualsales.component';
import { WinsComponent } from './modules/ieopportunity/wins/wins.component';
import { ImpersonationComponent } from './modules/impersonation/impersonation.component';

// Modules Ccomponents
import { BacklogGridComponent } from './modules/backlog/components/backlog-grid/backlog-grid.component';
import { ActualSalesGridComponent } from './modules/ieopportunity/actualsales/components/actualsales-grid/actualsales-grid.component';
import { WinsGridComponent } from './modules/ieopportunity/wins/components/wins-grid/wins-grid.component';
import { OpportunitiesGridComponent } from './modules/ieopportunity/opportunities/components/opportunities-grid/opportunities-grid.component';
import { PhasingGridComponent } from './modules/phasing/components/phasing-grid/phasing-grid.component';
import { SpeculativeGridComponent } from './modules/speculative/components/speculative-grid/speculative-grid.component';

// Base Components
import { OptionsListComponent } from './shared/components/base/options-list/options-list.component';
import { CheckboxListComponent } from './shared/components/base/options-list/components/checkbox-list/checkbox-list.component';
import { RadioButtonListComponent } from './shared/components/base/options-list/components/radio-button-list/radio-button-list.component';
import { CheckboxGroupsComponent } from './shared/components/base/checkbox-groups/checkbox-groups.component';
import { CheckboxGroupsHeaderComponent } from './shared/components/base/checkbox-groups/components/checkbox-groups-header/checkbox-groups-header.component';
import { CheckboxGroupsValuesComponent } from './shared/components/base/checkbox-groups/components/checkbox-groups-values/checkbox-groups-values.component';
import { VirtualListDropdownComponent } from './shared/components/base/virtual-list-dropdown/virtual-list-dropdown.component';
import { TreeViewComponent } from './shared/components/base/tree-view/tree-view.component';
import { TreeViewCheckItemComponent } from './shared/components/base/tree-view/components/tree-view-check-item/tree-view-check-item.component';
import { TreeViewRadioItemComponent } from './shared/components/base/tree-view/components/tree-view-radio-item/tree-view-radio-item.component';
import { SearchInputComponent } from './shared/components/base/search-input/search-input.component';
import { SelectionControlComponent } from './shared/components/base/selection-control/selection-control.component';
import { SpinnerComponent } from './shared/components/base/spinner/spinner.component';
import { SwitchComponent } from './shared/components/base/switch/switch.component';
import { DropDownComponent } from './shared/components/base/drop-down/drop-down.component';
import { GridComponent } from './shared/components/base/grid/grid.component';
import { GridToolbarComponent } from './shared/components/base/grid/components/grid-toolbar/grid-toolbar.component';
import { LinkCellComponent } from './shared/components/base/grid/components/cell-renderers/link-cell/link-cell.component';
import { IconCellComponent } from './shared/components/base/grid/components/cell-renderers/icon-cell/icon-cell.component';
import { DateCellComponent } from './shared/components/base/grid/components/cell-renderers/date-cell/date-cell.component';
import { PercentageCellComponent } from './shared/components/base/grid/components/cell-renderers/percentage-cell/percentage-cell.component';
import { NumericCellComponent } from './shared/components/base/grid/components/cell-renderers/numeric-cell/numeric-cell.component';
import { StringCellComponent } from './shared/components/base/grid/components/cell-renderers/string-cell/string-cell.component';
import { TotalsRowComponent } from './shared/components/base/grid/components/cell-renderers/totals-row/totals-row.component';
import { NumericEditorComponent } from './shared/components/base/grid/components/cell-renderers/numeric-editor/numeric-editor.component';
import { GridLoadingComponent } from './shared/components/base/grid/components/cell-renderers/grid-loading/grid-loading.component';
import { RealNumberFilterComponent } from './shared/components/base/grid/components/cell-renderers/real-number-filter/real-number-filter.component';
import { AutoCompleteComponent } from './shared/components/base/auto-complete/auto-complete.component';
import { DropDownMultipleComponent } from './shared/components/base/drop-down-multiple/drop-down-multiple.component';
import { UnorderedListComponent } from './shared/components/base/unordered-list/unordered-list.component';

//Shared Components
import { CustomerPickerComponent } from './shared/components/customer-picker/customer-picker.component';
import { TimeframePickerComponent } from './shared/components/timeframe-picker/timeframe-picker.component';
import { CurrencyPickerComponent } from './shared/components/currency-picker/currency-picker.component';
import { TimeframeComponent } from './shared/components/timeframe/timeframe.component';
import { CurrencyComponent } from './shared/components/currency/currency.component';
import { FiltersComponent } from './shared/components/filters/filters.component';
import { FiltersPickerComponent } from './shared/components/filters-picker/filters-picker.component';
import { AppliedFiltersTooltipComponent } from './shared/components/filters-picker/components/applied-filters-tooltip/applied-filters-tooltip.component';
import { AppToastsComponent } from './shared/components/app-toasts/app-toasts.component';
import { AppMessageContentComponent } from './shared/components/app-message-content/app-message-content.component';
import { NavbarComponent } from './shared/components/navbar/navbar.component';
import { ImpersonationIndicatorComponent } from './shared/components/impersonation-indicator/impersonation-indicator.component';
import { TrainingModeIndicatorComponent } from './shared/components/training-mode-indicator/training-mode-indicator.component';
import { AdminIndicatorComponent } from './shared/components/admin-indicator/admin-indicator.component';
import { ReadonlyModeIndicatorComponent } from './shared/components/readonly-mode-indicator/readonly-mode-indicator.component';
import { WmuFcComponent } from './shared/components/filters/components/wmu-fc/wmu-fc.component';
import { MarketComponent } from './shared/components/filters/components/market/market.component';
import { ClientGroupComponent } from './shared/components/filters/components/client-group/client-group.component';
import { ServiceGroupComponent } from './shared/components/filters/components/service-group/service-group.component';
import { IndustryComponent } from './shared/components/filters/components/industry/industry.component';
import { FunctionsComponent } from './shared/components/filters/components/functions/functions.component';
import { GrowthPrioritiesComponent } from './shared/components/filters/components/growth-priorities/growth-priorities.component';
import { AlliancesComponent } from './shared/components/filters/components/alliances/alliances.component';
import { DataRefreshComponent } from './shared/components/data-refresh/data-refresh.component';
import { ActualsIndicatorComponent } from './shared/components/actuals-indicator/actuals-indicator.component';
import { OpportunityPhasingModalComponent } from './shared/components/opportunity-phasing-modal/opportunity-phasing-modal.component';
import { SpeculativeModalComponent } from './shared/components/speculative-modal/speculative-modal.component';
import { BoardPickerComponent } from './shared/components/board-picker/board-picker.component';
import { BoardModalComponent } from './shared/components/board-modal/board-modal.component';
import { SpeculativeEditGridComponent } from './shared/components/speculative-modal/components/speculative-edit-grid/speculative-edit-grid.component';
import { PhasingEditGridComponent } from './shared/components/opportunity-phasing-modal/components/phasing-edit-grid/phasing-edit-grid.component';
import { AnalyticsGridComponent } from './modules/analytics/components/analytics-grid/analytics-grid.component';
import { ShareBoardModalComponent } from './shared/components/board-picker/components/share-board-modal/share-board-modal.component';
import { FavoritePickerComponent } from './modules/analytics/components/favorite-picker/favorite-picker.component';
import { FavoriteModalComponent } from './modules/analytics/components/favorite-modal/favorite-modal.component';
import { ShareFavoriteModalComponent } from './modules/analytics/components/favorite-picker/share-favorite-modal/share-favorite-modal.component';
import { MsaFilterComponent } from './shared/components/msa-filter/msa-filter.component';
import { ShareBoardComponent } from './shared/components/board-picker/components/share-board/share-board.component';
import { TabsComponent } from './shared/components/board-modal/components/tabs-picker/components/tab/tab.component';
import { TabsPickerComponent } from './shared/components/board-modal/components/tabs-picker/tabs-picker.component';
import { BoardBacklogTabComponent } from './shared/components/board-modal/components/board-backlog-tab/board-backlog-tab.component';
import { BoardProjectionTabComponent } from './shared/components/board-modal/components/board-projection-tab/board-projection-tab.component';
import { BoardOpportunitiesTabComponent } from './shared/components/board-modal/components/board-projection-tab/components/board-opportunities-tab/board-opportunities-tab.component';
import { BoardActualSalesTabComponent } from './shared/components/board-modal/components/board-projection-tab/components/board-actual-sales-tab/board-actual-sales-tab.component';
import { BoardWinsTabComponent } from './shared/components/board-modal/components/board-projection-tab/components/board-wins-tab/board-wins-tab.component';
import { BoardOverviewTabComponent } from './shared/components/board-modal/components/board-overview-tab/board-overview-tab.component';
import { BoardPhasingTabComponent } from './shared/components/board-modal/components/board-phasing-tab/board-phasing-tab.component';
import { BoardSpeculativeTabComponent } from './shared/components/board-modal/components/board-speculative-tab/board-speculative-tab.component';
import { BoardAnalyticsTabComponent } from './shared/components/board-modal/components/board-analytics-tab/board-analytics-tab.component';
import { FiltersAccordionComponent } from './shared/components/filters-accordion/filters-accordion.component';

// Shared / Tiles
import { SalesTileComponent } from './shared/components/tiles/projection-tile/components/sales-tile/sales-tile.component';
import { SalesGCPTileComponent } from './shared/components/tiles/projection-tile/components/sales-gcp-tile/sales-gcp-tile';
import { RevenueTileComponent } from './shared/components/tiles/projection-tile/components/revenue-tile/revenue-tile.component';
import { CciTileComponent } from './shared/components/tiles/projection-tile/components/cci-tile/cci-tile.component';
import { WonCciTileComponent } from './shared/components/tiles/projection-tile/components/won-cci-tile/won-cci-tile.component';
import { SalesGilcTileComponent } from './shared/components/tiles/gilc-tile/components/sales-gilc-tile/sales-gilc-tile.component';
import { CciGilcTileComponent } from './shared/components/tiles/gilc-tile/components/cci-gilc-tile/cci-gilc-tile.component';
import { RevenueGilcTileComponent } from './shared/components/tiles/gilc-tile/components/revenue-gilc-tile/revenue-gilc-tile.component';
import { TargetsTileComponent } from './shared/components/tiles/targets-tile/targets-tile.component';
import { TargetsChartComponent } from './shared/components/tiles/targets-tile/components/targets-chart/targets-chart.component';
import { TrendTileComponent } from './shared/components/tiles/trend-tile/trend-tile.component';
import { TrendChartComponent } from './shared/components/tiles/trend-tile/components/trend-chart/trend-chart.component';
import { TrendTableComponent } from './shared/components/tiles/trend-tile/components/trend-table/trend-table.component';
import { TrendModalComponent } from './shared/components/tiles/trend-modal/trend-modal.component';
import { TrendFiltersComponent } from './shared/components/tiles/trend-tile/components/trend-filters/trend-filters.component';

// Services
import { SharedService } from './shared/services/shared.service';
import { SecurityService } from './shared/services/security.service';
import { AppStateService } from './shared/services/app-state.service';
import { AppMessagesService } from './shared/services/app-messages.service';
import { AppToastsService } from './shared/services/app-toasts.service';
import { FileExporterService } from './shared/services/file-exporter.service';
import { FiltersService } from './shared/services/filters.service';
import { FilterDefaultsService } from './shared/services/filter-defaults.service';
import { ProjectionTileService } from './shared/services/tiles/projection-tile.service';
import { GilcTileService } from './shared/services/tiles/gilc-tile.service';
import { TargetTileService } from './shared/services/tiles/target-tile.service';
import { TrendTileService } from './shared/services/tiles/trend-tile.service';
import { TrendModalService } from './shared/services/tiles/trend-modal.service';
import { ErrorHandlerService } from './core/services/error-handler.service';
import { BacklogGridService } from './shared/services/grids/backlog-grid.service';
import { OpportunitiesGridService } from './shared/services/grids/opportunities-grid.service';
import { WinsGridService } from './shared/services/grids/wins-grid.service';
import { ActualSalesGridService } from './shared/services/grids/actualsales-grid.service';
import { PhasingGridService } from './shared/services/grids/phasing-grid.service';
import { SpeculativeGridService } from './shared/services/grids/speculative-grid.service';
import { OpportunityPhasingModalService } from './shared/services/modals/opportunity-phasing-modal.service';
import { MrdrService } from './shared/services/mrdr.service';
import { CleanUpNotificatorService } from './shared/services/clean-up-notificator.service';
import { DatadogSessionService } from './core/services/datadog-session.service';

// Guards
import { CustomAuthGuard } from './core/guards/custom-auth.guard';
import { MaintenanceModeGuard } from './shared/guards/maintenance-mode.guard';
import { TabGuard } from './shared/guards/tab.guard';

// Pipes
import { FilterArrayPipe } from './shared/pipes/filter-array.pipe';
import { CustomNumberPipe } from './shared/pipes/custom-number.pipe';
import { HtmlSanitizerPipe } from './shared/pipes/html-sanitizer.pipe';

@NgModule({
  declarations: [
    AppComponent,
    UniversalHeaderComponent,
    ModalComponent,
    AlertComponent,
    ToastComponent,
    ChatComponent,
    OverviewComponent,
    IeopportunityComponent,
    PhasingComponent,
    SpeculativeComponent,
    BacklogComponent,
    AnalyticsComponent,
    OpportunitiesComponent,
    ActualSalesComponent,
    WinsComponent,
    CustomerPickerComponent,
    TimeframePickerComponent,
    TimeframeComponent,
    CurrencyPickerComponent,
    CurrencyComponent,
    ActualsIndicatorComponent,
    TimeframeComponent,
    CurrencyComponent,
    FiltersComponent,
    ImpersonationComponent,
    AppToastsComponent,
    AppMessageContentComponent,
    NavbarComponent,
    ImpersonationIndicatorComponent,
    TrainingModeIndicatorComponent,
    AdminIndicatorComponent,
    ReadonlyModeIndicatorComponent,
    FilterArrayPipe,
    CustomNumberPipe,
    HtmlSanitizerPipe,
    VirtualListDropdownComponent,
    TreeViewComponent,
    TreeViewCheckItemComponent,
    TreeViewRadioItemComponent,
    SearchInputComponent,
    SelectionControlComponent,
    CheckboxListComponent,
    RadioButtonListComponent,
    CheckboxGroupsComponent,
    OptionsListComponent,
    WmuFcComponent,
    MarketComponent,
    ClientGroupComponent,
    ServiceGroupComponent,
    IndustryComponent,
    FunctionsComponent,
    GrowthPrioritiesComponent,
    AlliancesComponent,
    SpinnerComponent,
    SwitchComponent,
    DropDownComponent,
    CheckboxGroupsHeaderComponent,
    CheckboxGroupsValuesComponent,
    FiltersPickerComponent,
    AppliedFiltersTooltipComponent,
    DataRefreshComponent,
    SalesTileComponent,
    SalesGCPTileComponent,
    RevenueTileComponent,
    CciTileComponent,
    WonCciTileComponent,
    SalesGilcTileComponent,
    CciGilcTileComponent,
    RevenueGilcTileComponent,
    TargetsTileComponent,
    TargetsChartComponent,
    TrendTileComponent,
    TrendChartComponent,
    TrendTableComponent,
    TrendModalComponent,
    TrendFiltersComponent,
    BoardPickerComponent,
    MsaFilterComponent,
    GridComponent,
    GridToolbarComponent,
    BacklogGridComponent,
    ActualSalesGridComponent,
    WinsGridComponent,
    OpportunitiesGridComponent,
    PhasingGridComponent,
    SpeculativeGridComponent,
    LinkCellComponent,
    IconCellComponent,
    DateCellComponent,
    PercentageCellComponent,
    NumericCellComponent,
    StringCellComponent,
    TotalsRowComponent,
    NumericEditorComponent,
    GridLoadingComponent,
    RealNumberFilterComponent,
    BoardModalComponent,
    OpportunityPhasingModalComponent,
    SpeculativeModalComponent,
    AutoCompleteComponent,
    SpeculativeEditGridComponent,
    PhasingEditGridComponent,
    AnalyticsGridComponent,
    ShareBoardModalComponent,
    FavoritePickerComponent,
    FavoriteModalComponent,
    DropDownMultipleComponent,
    ShareFavoriteModalComponent,
    UnorderedListComponent,
    ShareBoardComponent,
    TabsPickerComponent,
    TabsComponent,
    BoardBacklogTabComponent,
    BoardProjectionTabComponent,
    BoardOpportunitiesTabComponent,
    BoardActualSalesTabComponent,
    BoardWinsTabComponent,
    BoardOverviewTabComponent,
    BoardSpeculativeTabComponent,
    BoardPhasingTabComponent,
    BoardAnalyticsTabComponent,
    FiltersAccordionComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    RebarAuthModule.forRoot(),
    MsalModule,
    NgbModule,
    FontAwesomeModule,
    VirtualScrollerModule,
    NgxSpinnerModule,
    ClipboardModule,
    ChartsModule,
    AgGridModule.withComponents([]),
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: (config: AppConfigService) => () => config.load(),
      deps: [AppConfigService],
      multi: true,
    },
    { provide: 'Window', useValue: window },
    { provide: ErrorHandler, useClass: ErrorHandlerService },
    MaintenanceModeGuard,
    CustomAuthGuard,
    TabGuard,
    FiltersService,
    FilterDefaultsService,
    AppToastsService,
    AppMessagesService,
    SharedService,
    SecurityService,
    AppStateService,
    FileExporterService,
    ProjectionTileService,
    GilcTileService,
    TargetTileService,
    TrendTileService,
    TrendModalService,
    BacklogGridService,
    OpportunitiesGridService,
    WinsGridService,
    ActualSalesGridService,
    PhasingGridService,
    SpeculativeGridService,
    OpportunityPhasingModalService,
    MrdrService,
    CleanUpNotificatorService,
    DatadogSessionService,
  ],
  bootstrap: [AppComponent, MsalRedirectComponent],
})
export class AppModule {}
