import { ChangeDetectorRef, Component } from '@angular/core';
import { Subject } from 'rxjs';

import { MetricType } from 'src/app/shared/constants/metrics.constants';

@Component({
  selector: 'app-actualsales',
  templateUrl: './actualsales.component.html',
  styleUrls: ['./actualsales.component.scss'],
})
export class ActualSalesComponent {
  openTrendModalEmittedEvent = new Subject<boolean>();
  isGridExpanded: boolean;

  constructor(private changeDetectorRef: ChangeDetectorRef) {}

  public get metricType(): typeof MetricType {
    return MetricType;
  }

  onOpenTrendModalEmitted(isChartView: boolean, type: MetricType): void {
    this.openTrendModalEmittedEvent.next(isChartView);
  }

  onExpandChanged(isExpanded: boolean): void {
    this.isGridExpanded = isExpanded;
    this.changeDetectorRef.detectChanges();
  }
}
