import {
  Component,
  ElementRef,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { combineLatest, ReplaySubject, Subject, Subscription } from 'rxjs';
import { ActivatedRoute, Params } from '@angular/router';
import { cloneDeep, difference, min } from 'lodash';
import * as Excel from 'exceljs/dist/exceljs.min.js';
import moment from 'moment-business-days';

import { ColDef, SelectionChangedEvent } from 'ag-grid';
import {
  GridToolbarConfig,
  GridToolbarDropdown,
  GridToolbarIcon,
  GridToolbarSwitch,
} from 'src/app/shared/components/base/grid/components/grid-toolbar/entities/grid-toolbar-config';
import { MetricType } from 'src/app/shared/constants/metrics.constants';
import { TextValuePair } from 'src/app/shared/services/entities/common/key-value';
import { SelectedFilters } from 'src/app/shared/services/entities/filters/selected-filters';
import {
  ComponentNames,
  GridColDefs,
  GridFilters,
} from './constants/speculative-grid.constants';
import { RequestPayload } from 'src/app/shared/services/entities/request-payload';
import {
  GridConfig,
  GridConfigFeatures,
} from 'src/app/shared/components/base/grid/entities/grid-config';
import { Filters } from 'src/app/shared/services/entities/filters/filters';
import { GridObservables } from 'src/app/shared/components/base/grid/entities/grid-observables';
import { MessageTemplates } from 'src/app/shared/constants/messages.constants';
import {
  AppMessage,
  AppMessageButton,
} from 'src/app/shared/services/entities/app-message';
import { GridCellIcon } from 'src/app/shared/components/base/grid/entities/grid-cell-icon';
import { SpeculativeModalConfig } from 'src/app/shared/components/speculative-modal/entities/speculative-modal-config';
import { SpeculativeResponseItem } from 'src/app/shared/services/entities/grids/speculative-response';
import { SpeculativeGridUtils } from './utils/speculative-grid.utils';
import {
  MetricsOptions,
  ViewOptions,
} from 'src/app/shared/constants/grid.constants';
import { ActiveDate } from 'src/app/shared/services/entities/filters/active-date';
import { ActiveDates } from 'src/app/shared/constants/filters.constants';
import { MmbDate } from 'src/app/shared/services/entities/filters/date';
import { CustomerFilters } from 'src/app/shared/services/entities/filters/customer-filters';
import { ReasonCode } from 'src/app/shared/services/entities/reason-code';
import { GridData } from 'src/app/shared/components/base/grid/entities/grid-data';
import { CleanUpItem } from 'src/app/shared/services/entities/clean-up';
import { GridValidationsUtils } from 'src/app/shared/utils/grid-validations.utils';
import { TabControlNames } from 'src/app/shared/components/board-modal/components/board-speculative-tab/constants/speculative-tab.constants';
import { BoardTabControl } from 'src/app/shared/services/entities/board-response';

import { SpeculativeGridService } from 'src/app/shared/services/grids/speculative-grid.service';
import { FiltersService } from 'src/app/shared/services/filters.service';
import { AppMessagesService } from 'src/app/shared/services/app-messages.service';
import { AppToastsService } from 'src/app/shared/services/app-toasts.service';
import { SecurityService } from 'src/app/shared/services/security.service';
import { SpeculativeModalService } from 'src/app/shared/services/modals/speculative-modal.service';
import { CleanUpNotificatorService } from 'src/app/shared/services/clean-up-notificator.service';
import { BoardService } from 'src/app/shared/services/board.service';

import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  faDownload,
  faExpand,
  faCompress,
  faPencilAlt,
  faEye,
  faPlusCircle,
  faTrash,
  faFileDownload,
  faFileUpload,
  faPlusSquare,
  faSyncAlt,
} from '@fortawesome/free-solid-svg-icons';
import { faPauseCircle } from '@fortawesome/free-regular-svg-icons';

@Component({
  selector: 'app-speculative-grid',
  templateUrl: './speculative-grid.component.html',
  styleUrls: ['./speculative-grid.component.scss'],
})
export class SpeculativeGridComponent implements OnInit, OnDestroy {
  @ViewChild('fileInput') fileInput: ElementRef;
  @Output() expandChangedEvent = new EventEmitter<boolean>();
  @Output() metricChangedEvent = new EventEmitter<TextValuePair>();

  subscription = new Subscription();

  loadChangedEvent = new Subject<boolean>();
  updateRowDataEvent = new ReplaySubject<GridData>(1);
  updateColDefsEvent = new ReplaySubject<Array<ColDef>>(1);
  filtersChangedEvent = new Subject<string>();

  filters: Filters;
  selectedFilters: SelectedFilters;
  customerFilters: CustomerFilters;
  params: Params;
  reasonCodes: Array<ReasonCode> = [];
  hasAccountPlan: boolean;
  isReadOnlyMode: boolean;
  isAdmin: boolean;
  gridToolbarConfig: GridToolbarConfig;
  gridConfig: GridConfig;
  speculatives: Array<SpeculativeResponseItem> = [];
  selectedSpeculatives: Array<SpeculativeResponseItem> = [];
  isExpanded: boolean;

  public get metricType(): typeof MetricType {
    return MetricType;
  }

  constructor(
    private filtersService: FiltersService,
    private speculativeGridService: SpeculativeGridService,
    private speculativeModalService: SpeculativeModalService,
    private securityService: SecurityService,
    private cleanUpNotificatorService: CleanUpNotificatorService,
    private appMessagesService: AppMessagesService,
    private appToastsService: AppToastsService,
    private activatedRoute: ActivatedRoute,
    private boardService: BoardService
  ) {}

  ngOnInit(): void {
    this.subscription.add(
      combineLatest([
        this.filtersService.globalFiltersChanged,
        this.filtersService.selectedFiltersChanged,
        this.filtersService.reasonCodesChanged,
        this.activatedRoute.queryParams,
      ]).subscribe(
        ([v, w, x, y]: [
          Filters,
          SelectedFilters,
          Array<ReasonCode>,
          Params
        ]) => {
          this.filters = v;
          const timeframeChanged: boolean =
            (this.selectedFilters &&
              this.selectedFilters.timeframe.getDescription() !==
                w.timeframe.getDescription()) ||
            false;
          this.selectedFilters = w;
          this.reasonCodes = x;
          const paramsChanged: boolean =
            (this.params && difference([this.params], [y])?.length > 0) ||
            false;
          this.params = y;

          Promise.all([
            this.securityService.hasAccountPlanRole(
              this.selectedFilters.customer.MasterCustomerNumber
            ),
            this.securityService.isReadOnly(),
            this.securityService.isAdmin(),
          ]).then((z) => {
            const accountPlanChanged: boolean = this.hasAccountPlan !== z[0];
            this.hasAccountPlan = z[0];
            this.isReadOnlyMode = z[1];
            this.isAdmin = z[2];

            const boardChanged: boolean =
              this.boardService.selectedBoard?.tab?.Name ===
              TabControlNames.speculative;

            if (
              !this.gridToolbarConfig ||
              paramsChanged ||
              accountPlanChanged ||
              boardChanged
            ) {
              this.initializeToolbar();
              this.initializeGridConfig();
            }

            this.validateFilterSelection(timeframeChanged);

            this.getData();
          });
        }
      )
    );

    this.subscription.add(
      this.filtersService.customerFiltersChanged.subscribe(
        (x: CustomerFilters) => {
          this.customerFilters = x;
        }
      )
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  initializeToolbar(): void {
    const targets: Array<TextValuePair> = GridFilters.TargetOptions(
      this.selectedFilters
    );

    let selectedMetric: TextValuePair = GridFilters.Metrics[0];
    let selectedPeriod: TextValuePair = GridFilters.Periods[0];
    let selectedTarget: TextValuePair = targets[0];

    let isNoActivity: boolean;

    if (
      this.boardService.selectedBoard?.tab?.Name === TabControlNames.speculative
    ) {
      const metricsDropdown: BoardTabControl =
        this.boardService.selectedBoard.tab.Controls.find(
          (x: BoardTabControl) => x.key === TabControlNames.metrics
        );
      const periodDropdown: BoardTabControl =
        this.boardService.selectedBoard.tab.Controls.find(
          (x: BoardTabControl) => x.key === TabControlNames.periods
        );
      const targetDropdown: BoardTabControl =
        this.boardService.selectedBoard.tab.Controls.find(
          (x: BoardTabControl) => x.key === TabControlNames.targets
        );
      const expandSwitch = this.boardService.selectedBoard.tab.Controls.find(
        (x: BoardTabControl) => x.key === TabControlNames.expand
      );
      isNoActivity = this.boardService.selectedBoard.tab.Controls.find(
        (x: BoardTabControl) => x.key === TabControlNames.noActivity
      )?.value;

      selectedMetric = GridFilters.Metrics.find(
        (x: TextValuePair) =>
          x.value.toLowerCase() === metricsDropdown.value.toLowerCase()
      );

      selectedPeriod = GridFilters.Periods.find(
        (x: TextValuePair) =>
          x.value.toLowerCase() === periodDropdown.value.toLowerCase()
      );

      selectedTarget = targets.find(
        (x: TextValuePair) =>
          x.value.toLowerCase() === targetDropdown.value.toLowerCase()
      );

      this.isExpanded = expandSwitch?.value;
      this.expandChangedEvent.emit(this.isExpanded);

      this.boardService.selectedBoard = null;
    } else {
      selectedMetric = this.params?.metric
        ? GridFilters.Metrics.find((x: TextValuePair) => {
            const isPercentage: boolean = this.params.isPercentage === 'true';
            switch (Number(this.params.metric)) {
              case MetricType.Sales:
                return x.value === MetricsOptions.Sales;
              case MetricType.Revenue:
                return x.value === MetricsOptions.Revenue;
              case MetricType.Cci:
                return (
                  (x.value === MetricsOptions.Cci && !isPercentage) ||
                  (x.value === MetricsOptions.CciPercentage && isPercentage)
                );
              default:
                return x.value === MetricsOptions.Summary;
            }
          })
        : GridFilters.Metrics[0];

      selectedPeriod = this.params?.period
        ? GridFilters.Periods.find(
            (x: TextValuePair) => x.value === this.params.period
          )
        : GridFilters.Periods[1];

      selectedTarget = this.params?.target
        ? targets.find((x: TextValuePair) => x.value === this.params.target)
        : targets[0];
    }

    if (selectedMetric.value !== GridFilters.Metrics[0].value) {
      this.metricChangedEvent.next(selectedMetric);
    }

    this.gridToolbarConfig = new GridToolbarConfig({
      componentName: ComponentNames.opportunities,
      controls: [
        new GridToolbarDropdown({
          controlName: ComponentNames.metrics,
          items: GridFilters.Metrics,
          onChange: this.onMetricChanged.bind(this),
          selected: selectedMetric,
        }),
        new GridToolbarDropdown({
          controlName: ComponentNames.periods,
          isVisible: this.isPeriodVisible,
          items: GridFilters.Periods,
          onChange: this.onPeriodChanged.bind(this),
          selected: selectedPeriod,
        }),
        new GridToolbarDropdown({
          controlName: ComponentNames.targets,
          items: targets,
          onChange: this.onTargetChanged.bind(this),
          selected: selectedTarget,
        }),
        new GridToolbarIcon({
          controlName: ComponentNames.add,
          icon: faPlusCircle as IconProp,
          onClick: this.onAddSpeculative.bind(this),
          text: 'ADD',
          isDisabled: () => {
            return !this.hasAccountPlan || this.isReadOnlyMode;
          },
        }),
        new GridToolbarIcon({
          controlName: ComponentNames.delete,
          icon: faTrash as IconProp,
          onClick: this.onDeleteSpeculatives.bind(this),
          text: 'DELETE',
          isDisabled: () => {
            return (
              !this.hasAccountPlan ||
              this.isReadOnlyMode ||
              this.selectedSpeculatives.length === 0
            );
          },
        }),
        new GridToolbarIcon({
          controlName: ComponentNames.hold,
          icon: faPauseCircle as IconProp,
          onClick: this.onHoldSpeculatives.bind(this),
          text: 'HOLD',
          isDisabled: () => {
            return (
              !this.hasAccountPlan ||
              this.isReadOnlyMode ||
              this.selectedSpeculatives.length === 0 ||
              this.selectedSpeculatives.every(
                (x: SpeculativeResponseItem) => x.status !== 'Active'
              )
            );
          },
        }),
        new GridToolbarIcon({
          controlName: ComponentNames.export,
          icon: faDownload as IconProp,
          onClick: this.onFileExport.bind(this),
          text: 'EXPORT',
          isDisabled: (x: GridToolbarConfig) => {
            const targetsDropdown: GridToolbarDropdown =
              this.gridToolbarConfig.findControl(ComponentNames.targets);

            return (
              targetsDropdown.selected.value === ViewOptions.CompareProjection
            );
          },
        }),
        new GridToolbarIcon({
          controlName: ComponentNames.expand,
          icon: faExpand as IconProp,
          onClick: () => {
            this.isExpanded = true;
            this.expandChangedEvent.emit(true);
          },
          text: 'EXPAND',
          isVisible: () => {
            return !this.isExpanded;
          },
        }),
        new GridToolbarIcon({
          controlName: ComponentNames.compress,
          icon: faCompress as IconProp,
          onClick: () => {
            this.isExpanded = false;
            this.expandChangedEvent.emit(false);
          },
          text: 'EXIT',
          isVisible: () => {
            return this.isExpanded;
          },
        }),
        new GridToolbarSwitch({
          controlName: ComponentNames.noActivity,
          text: 'NO ACTIVITY',
          onSwitch: this.onNoActivitySwitchChanged.bind(this),
          switchValue: isNoActivity,
        }),
        new GridToolbarIcon({
          controlName: ComponentNames.downloadTemplate,
          icon: faFileDownload as IconProp,
          onClick: this.onDownloadTemplate.bind(this),
          text: 'DOWNLOAD TEMPLATE',
          isDisabled: () => {
            return !this.hasAccountPlan && !this.isAdmin;
          },
        }),
        new GridToolbarIcon({
          controlName: ComponentNames.uploadTemplate,
          icon: faFileUpload as IconProp,
          onClick: this.onUploadTemplate.bind(this),
          text: 'UPLOAD TEMPLATE',
          isDisabled: () => {
            return !this.hasAccountPlan || this.isReadOnlyMode;
          },
        }),
      ],
    });
  }

  initializeGridConfig(): void {
    this.gridConfig =
      this.gridConfig ??
      new GridConfig({
        columns: [],
        features: new GridConfigFeatures({
          hasTotalsRow: true,
        }),
        options: GridColDefs.getExtraGridOptions(),
        observables: new GridObservables({
          loadChanged: this.loadChangedEvent,
          updateColDefs: this.updateColDefsEvent,
          updateRowData: this.updateRowDataEvent,
          filterChanged: this.filtersChangedEvent,
          expandChanged: this.expandChangedEvent,
        }),
      });

    this.gridConfig.observables.selectionChanged.subscribe(
      (x: SelectionChangedEvent) => {
        this.selectedSpeculatives = x.api.getSelectedRows();
      }
    );

    this.updateColDefs();
  }

  updateColDefs(): void {
    this.updateColDefsEvent.next(
      GridColDefs.getColDefs(
        this.gridToolbarConfig,
        this.filters,
        this.selectedFilters,
        [
          new GridCellIcon({
            icon: faPencilAlt as IconProp,
            tooltip: 'Edit',
            onClick: this.onEditSpeculative.bind(this),
            showIconByRow: (x: SpeculativeResponseItem) => {
              return (
                this.hasAccountPlan &&
                !this.isReadOnlyMode &&
                x.status === 'Active'
              );
            },
          }),
          new GridCellIcon({
            icon: faSyncAlt as IconProp,
            tooltip: 'Activate',
            onClick: this.onActivateSpeculative.bind(this),
            showIconByRow: (x: SpeculativeResponseItem) => {
              return (
                this.hasAccountPlan &&
                !this.isReadOnlyMode &&
                x.status !== 'Active'
              );
            },
          }),
          new GridCellIcon({
            icon: faEye as IconProp,
            tooltip: 'View',
            onClick: this.onViewSpeculative.bind(this),
            showIconByRow: (x: SpeculativeResponseItem) => {
              return (
                (!this.hasAccountPlan || this.isReadOnlyMode) &&
                x.status === 'Active'
              );
            },
          }),
          new GridCellIcon({
            icon: faPlusSquare as IconProp,
            tooltip: 'Duplicate',
            onClick: this.onDuplicateSpeculative.bind(this),
            showIconByRow: (x: SpeculativeResponseItem) => {
              return (
                this.hasAccountPlan &&
                !this.isReadOnlyMode &&
                x.status === 'Active'
              );
            },
          }),
        ]
      )
    );
  }

  getData(): void {
    this.loadChangedEvent.next(true);

    const targetsDropdown: GridToolbarDropdown =
      this.gridToolbarConfig.findControl(ComponentNames.targets);

    const extraParams: Record<string, any> = {
      dataSet: targetsDropdown.selected.value,
      sapCode: '-1',
      sapCodePlan: this.selectedFilters.plan.year.toString(),
    };

    this.speculativeGridService
      .getSpeculatives(
        RequestPayload.createRequest(this.selectedFilters, extraParams)
      )
      .then((x: Array<SpeculativeResponseItem>) => {
        this.speculatives = x;
        this.showNotifications();
        this.updateRowDataEvent.next(this.mapToGridData(x));
        this.loadChangedEvent.next(false);
      });
  }

  mapToGridData(response: Array<SpeculativeResponseItem>): GridData {
    let speculatives: Array<SpeculativeResponseItem> = cloneDeep(response);

    const noActivitySwitch: GridToolbarSwitch =
      this.gridToolbarConfig.findControl(ComponentNames.noActivity);

    const metricsDropdown: GridToolbarDropdown =
      this.gridToolbarConfig.findControl(ComponentNames.metrics);

    const periodsDropdown: GridToolbarDropdown =
      this.gridToolbarConfig.findControl(ComponentNames.periods);

    if (noActivitySwitch.switchValue) {
      speculatives = speculatives.filter(
        (x: SpeculativeResponseItem) =>
          !x.isInactive(
            metricsDropdown.selected.value,
            periodsDropdown.selected.value
          )
      );
    }

    const isCustom: boolean =
      this.selectedFilters.timeframe.title === 'Custom' ? true : false;

    return new GridData({
      rows: SpeculativeGridUtils.mapData(
        speculatives,
        this.filters,
        this.gridToolbarConfig,
        isCustom
      ),
      pinnedTop: [],
    });
  }

  showNotifications(): void {
    const applicationDate: ActiveDate = this.filters.activeDates.find(
      (x: ActiveDate) => x.DateTypeCd === ActiveDates.ApplicationDate
    );

    let elapsedMonth: MmbDate = this.filters.dates.find(
      (x: MmbDate) => x.Id === applicationDate.StartTimeId
    );

    if (moment()['_d'] < moment(elapsedMonth.DateTime).businessAdd(3)['_d']) {
      elapsedMonth = this.filters.dates.find(
        (x: MmbDate) => x.Id === applicationDate.StartTimeId - 1
      );
    }

    this.cleanUpNotificatorService
      .getNotification(this.selectedFilters.customer.MasterCustomerNumber)
      .then((x: CleanUpItem) => {
        if (!x || x.elapsedDate.Id < elapsedMonth.Id) {
          const elapsedDatesMessage: AppMessage =
            MessageTemplates.SpeculativesElapsedDates;
          elapsedDatesMessage.buttons.forEach((y: AppMessageButton) => {
            y.action = () => {
              !x
                ? this.cleanUpNotificatorService.createNotification(
                    elapsedMonth,
                    this.selectedFilters.customer
                  )
                : this.cleanUpNotificatorService.updateNotification(
                    x,
                    elapsedMonth,
                    this.selectedFilters.customer
                  );

              this.appMessagesService.close(elapsedDatesMessage.id);
            };
          });

          this.appMessagesService.show(
            MessageTemplates.SpeculativesElapsedDates,
            {
              centered: true,
            }
          );
        }
      });
  }

  isPeriodVisible(config: GridToolbarConfig): boolean {
    const metricDropdown: GridToolbarDropdown = config.findControl(
      ComponentNames.metrics
    );
    return metricDropdown.selected.value !== MetricsOptions.Summary;
  }

  validateFilterSelection(timeframeChanged?: boolean): void {
    let colDefsUpdated = false;

    const periodsDropdown: GridToolbarDropdown =
      this.gridToolbarConfig.findControl(ComponentNames.periods);

    const targetsDropdown: GridToolbarDropdown =
      this.gridToolbarConfig.findControl(ComponentNames.targets);

    GridValidationsUtils.validatePeriodSelection(
      periodsDropdown,
      this.selectedFilters,
      () => {
        this.updateColDefs();
        colDefsUpdated = true;
      }
    );

    GridValidationsUtils.validateSubMetricSelection(
      targetsDropdown,
      this.filters,
      this.selectedFilters,
      () => {
        this.updateColDefs();
        colDefsUpdated = true;
      }
    );

    if (timeframeChanged && !colDefsUpdated) {
      this.updateColDefs();
    }
  }

  onMetricChanged(
    selected: TextValuePair,
    dropdown: GridToolbarDropdown
  ): void {
    dropdown.selected = selected;
    this.updateColDefs();
    this.updateRowDataEvent.next(this.mapToGridData(this.speculatives));
    selected =
      selected.value === MetricsOptions.Summary
        ? GridFilters.Metrics[2]
        : selected;
    this.metricChangedEvent.next(selected);
  }

  onPeriodChanged(
    selected: TextValuePair,
    dropdown: GridToolbarDropdown
  ): void {
    dropdown.selected = selected;
    this.updateColDefs();
    this.updateRowDataEvent.next(this.mapToGridData(this.speculatives));
  }

  onTargetChanged(
    selected: TextValuePair,
    dropdown: GridToolbarDropdown
  ): void {
    dropdown.selected = selected;
    this.updateColDefs();
    this.getData();
  }

  onFileExport(): void {
    new Promise(
      (
        resolve: (x: Record<string, boolean>) => void,
        reject: () => void
      ): void => {
        const sgMessage: AppMessage = MessageTemplates.ExportSgBreakdown;
        const wmuMessage: AppMessage = MessageTemplates.ExportWmuBreakdown;
        let isSgBreakdown = false;
        let isWmuBreakdown = false;
        sgMessage.buttons.forEach((x: AppMessageButton) => {
          x.action = (): void => {
            isSgBreakdown = x.text === 'Yes';
            this.appMessagesService.close(sgMessage.id);
            this.appMessagesService.show(wmuMessage, { centered: true });
          };
        });
        wmuMessage.buttons.forEach((x: AppMessageButton) => {
          x.action = (): void => {
            isWmuBreakdown = x.text === 'Yes';
            this.appMessagesService.close(wmuMessage.id);
            resolve({
              isSgBreakdown,
              isWmuBreakdown,
            });
          };
        });
        this.appMessagesService.show(sgMessage, { centered: true });
      }
    ).then((x: Record<string, boolean>) => {
      this.appMessagesService.show(MessageTemplates.FileExporterInit, {
        centered: true,
      });
      this.speculativeGridService
        .export(this.selectedFilters, this.gridToolbarConfig, x)
        .then((x: boolean) => {
          if (!x) {
            this.appMessagesService.show(MessageTemplates.UnexpectedError, {
              centered: true,
            });
          }
        });
    });
  }

  onSearchTextChanged(searchText: string): void {
    this.filtersChangedEvent.next(searchText);
  }

  onNoActivitySwitchChanged(
    value: boolean,
    switchControl: GridToolbarSwitch
  ): void {
    switchControl.switchValue = value;
    this.updateRowDataEvent.next(this.mapToGridData(this.speculatives));
  }

  onAddSpeculative(): void {
    if (this.hasAccountPlan && !this.isReadOnlyMode) {
      const targetsDropdown: GridToolbarDropdown =
        this.gridToolbarConfig.findControl(ComponentNames.targets);

      this.speculativeModalService.openSpeculativeModalEmitted.next(
        new SpeculativeModalConfig({
          filters: this.filters,
          selectedFilters: this.selectedFilters,
          customerFilters: this.customerFilters,
          reasonCodes: this.reasonCodes,
          dataSet: targetsDropdown.selected.value,
          isReadOnly: false,
          isAdmin: this.isAdmin,
          speculativeId: '',
        })
      );
    }
  }

  onDeleteSpeculatives(): void {
    this.loadChangedEvent.next(true);

    const deleteConfirmMessage: AppMessage =
      MessageTemplates.SpeculativeDeleteConfirm;

    deleteConfirmMessage.buttons.forEach((x: AppMessageButton) => {
      x.action = (): void => {
        if (x.text === 'Yes') {
          if (
            this.hasAccountPlan &&
            !this.isReadOnlyMode &&
            this.selectedSpeculatives.length > 0
          ) {
            this.speculativeGridService
              .deleteSpeculatives(
                this.selectedSpeculatives,
                this.selectedFilters
              )
              .then(() => {
                this.appMessagesService.close(deleteConfirmMessage.id);
                this.filtersService.applyFilters();
                this.selectedSpeculatives = [];
                this.appMessagesService.show(
                  MessageTemplates.SpeculativeSuccessfulDelete,
                  {
                    centered: true,
                  }
                );
                this.loadChangedEvent.next(false);
              });
          }
        } else {
          this.appMessagesService.close(deleteConfirmMessage.id);
        }
      };
    });

    this.appMessagesService.show(deleteConfirmMessage, {
      centered: true,
    });
  }

  onHoldSpeculatives(): void {
    this.loadChangedEvent.next(true);

    if (
      this.hasAccountPlan &&
      !this.isReadOnlyMode &&
      this.selectedSpeculatives.length > 0 &&
      this.selectedSpeculatives.some(
        (x: SpeculativeResponseItem) => x.status === 'Active'
      )
    ) {
      const speculatives: Array<SpeculativeResponseItem> =
        this.selectedSpeculatives.filter(
          (x: SpeculativeResponseItem) => x.status === 'Active'
        );

      this.speculativeGridService
        .updateStatus(speculatives, 'hold', this.selectedFilters)
        .then(() => {
          this.filtersService.applyFilters();
          this.loadChangedEvent.next(false);
          this.selectedSpeculatives = [];
          this.appMessagesService.show(
            MessageTemplates.SpeculativeSuccessfulHold,
            {
              centered: true,
            }
          );
        });
    }
  }

  onDownloadTemplate(): void {
    if (this.hasAccountPlan || this.isAdmin) {
      this.appMessagesService.show(
        MessageTemplates.SpeculativeTemplateDownload,
        {
          centered: true,
        }
      );

      this.speculativeGridService
        .downloadTemplate(this.selectedFilters.customer)
        .then((x: boolean) => {
          if (!x) {
            this.appMessagesService.show(MessageTemplates.UnexpectedError, {
              centered: true,
            });
          }
        });
    }
  }

  onUploadTemplate(): void {
    if (this.hasAccountPlan && !this.isReadOnlyMode) {
      const event = new MouseEvent('click', { bubbles: true });
      this.fileInput.nativeElement.dispatchEvent(event);
    }
  }

  onEditSpeculative(speculative: SpeculativeResponseItem): void {
    const targetsDropdown: GridToolbarDropdown =
      this.gridToolbarConfig.findControl(ComponentNames.targets);

    this.speculativeModalService.openSpeculativeModalEmitted.next(
      new SpeculativeModalConfig({
        filters: this.filters,
        selectedFilters: this.selectedFilters,
        customerFilters: this.customerFilters,
        reasonCodes: this.reasonCodes,
        dataSet: targetsDropdown.selected.value,
        isReadOnly: false,
        isAdmin: this.isAdmin,
        isDuplicate: false,
        speculativeId: speculative.id,
      })
    );
  }

  onViewSpeculative(speculative: SpeculativeResponseItem): void {
    const targetsDropdown: GridToolbarDropdown =
      this.gridToolbarConfig.findControl(ComponentNames.targets);

    this.speculativeModalService.openSpeculativeModalEmitted.next(
      new SpeculativeModalConfig({
        filters: this.filters,
        selectedFilters: this.selectedFilters,
        customerFilters: this.customerFilters,
        reasonCodes: this.reasonCodes,
        dataSet: targetsDropdown.selected.value,
        isReadOnly: true,
        isAdmin: this.isAdmin,
        speculativeId: speculative.id,
      })
    );
  }

  onDuplicateSpeculative(speculative: SpeculativeResponseItem): void {
    const targetsDropdown: GridToolbarDropdown =
      this.gridToolbarConfig.findControl(ComponentNames.targets);

    this.speculativeModalService.openSpeculativeModalEmitted.next(
      new SpeculativeModalConfig({
        filters: this.filters,
        selectedFilters: this.selectedFilters,
        customerFilters: this.customerFilters,
        reasonCodes: this.reasonCodes,
        dataSet: targetsDropdown.selected.value,
        isReadOnly: false,
        isAdmin: this.isAdmin,
        isDuplicate: true,
        speculativeId: speculative.id,
      })
    );
  }

  onActivateSpeculative(speculative: SpeculativeResponseItem): void {
    this.loadChangedEvent.next(true);

    this.speculativeGridService
      .updateStatus([speculative], 'activate', this.selectedFilters)
      .then(() => {
        this.filtersService.applyFilters();
        this.appMessagesService.show(
          MessageTemplates.SpeculativeSuccessfulActivate,
          {
            centered: true,
          }
        );
        this.loadChangedEvent.next(false);
      });
  }

  onFileChanged(event: any) {
    if (event.target.files.length > 0) {
      const file: File = event.target.files[0];
      const extension: string = file.name.split('.').pop();
      const validExtensions: Array<string> = ['xls', 'xlsx'];

      if (validExtensions.indexOf(extension) >= 0) {
        const workbook: Excel.Workbook = new Excel.Workbook();
        const reader: FileReader = new FileReader();
        reader.readAsArrayBuffer(file);
        this.fileInput.nativeElement.value = '';

        reader.onload = () => {
          const buffer: string | ArrayBuffer = reader.result;
          workbook.xlsx.load(buffer).then((wb: any) => {
            this.getSpeculativeRowsFromExcel(wb);
          });
        };
      } else {
        this.appMessagesService.show(
          MessageTemplates.SpeculativeUploadFileInvalidTemplate,
          { centered: true }
        );
        this.fileInput.nativeElement.value = '';
      }
    }
  }

  private getSpeculativeRowsFromExcel(workbook: Excel.Workbook) {
    const listsSheet: Excel.Worksheet = workbook.getWorksheet(2);
    const records: Array<Record<string, any>> = [];
    const applicationDate: ActiveDate = this.filters.activeDates.find(
      (x: ActiveDate) => x.DateTypeCd === ActiveDates.ApplicationDate
    );

    const excelConfig: any = {
      groups: [
        {
          from: 1,
          to: 11,
          name: 'metadata',
          validateRequired: true,
          isFinancial: false,
        },
        {
          from: 12,
          to: 14,
          name: 'metadata',
          validateRequired: false,
          isFinancial: false,
        },
        {
          from: 90,
          to: 92,
          name: 'metadata',
          validateRequired: false,
          isFinancial: false,
        },
        {
          from: 15,
          to: 39,
          name: 'sales',
          validateRequired: false,
          isFinancial: true,
        },
        {
          from: 40,
          to: 64,
          name: 'revenue',
          validateRequired: false,
          isFinancial: true,
        },
        {
          from: 65,
          to: 89,
          name: 'cci',
          validateRequired: false,
          isFinancial: true,
        },
      ],
      listColumns: [1, 2, 3, 4, 5, 6, 7, 9],
    };

    let message: AppMessage;

    if (listsSheet && listsSheet.name === 'Lists') {
      if (
        listsSheet.getCell('I1').value ===
        this.selectedFilters.customer.MasterCustomerNumber
      ) {
        const specsSheet: Excel.Worksheet = workbook.getWorksheet(3);
        if (specsSheet && specsSheet.name === 'Upload Template') {
          const headerRow: any = specsSheet.getRow(2);
          specsSheet.eachRow(
            { includeEmpty: false },
            (row: any, rowNumber: number) => {
              if (rowNumber > 2 && !this.isEmptyRow(row)) {
                records.push(
                  this.parseExcelRowToSpecRecord(
                    headerRow,
                    row,
                    excelConfig,
                    applicationDate
                  )
                );
              }
            }
          );
          this.saveSpeculativesFromExcel(records);
        } else {
          message = MessageTemplates.SpeculativeUploadFileInvalidTemplate;
        }
      } else {
        message = MessageTemplates.SpeculativeUploadFileInvalidTemplateCustomer;
      }
    } else {
      message = MessageTemplates.SpeculativeUploadFileInvalidTemplate;
    }

    if (message) {
      this.fileInput.nativeElement.value = '';
      this.appMessagesService.show(message, { centered: true });
    }
  }

  private isEmptyRow(row: any): any {
    const cells: Array<any> = row.model.cells;
    const formulaCells: Array<any> = cells
      .filter((x: any) => x.formula)
      .map((x: any) => x.result);
    const dataCells: Array<any> = cells
      .filter((x: any) => !x.formula)
      .map((x: any) => x.value);
    return (
      formulaCells.every((x: any) => !x || x === 0) &&
      dataCells.every((x: any) => !x)
    );
  }

  private parseExcelRowToSpecRecord(
    headerRow: any,
    row: any,
    excelConfig: any,
    applicationDate: ActiveDate
  ): Record<string, any> {
    const record: Record<string, any> = {
      metadata: [],
      sales: [],
      revenue: [],
      cci: [],
      row: row.number,
    };

    // We get fiscal year from template instead of using the ApplicationDate in order to match data with column headers
    const cfy = Number(
      moment(headerRow.getCell(15).value.slice(-2), 'YY').format('YYYY')
    );
    const cfyStartTimeId: number = min(
      this.filters.dates
        .filter((x: MmbDate) => x.FiscalYearNbr === cfy)
        .map((x: MmbDate) => x.Id)
        .sort()
    );

    row.eachCell({ includeEmpty: true }, (cell: any, cellNumber: number) => {
      const dataType: any = excelConfig.groups.find(
        (x: any) => x.from <= cellNumber && x.to >= cellNumber
      );
      const headerName: any = headerRow.getCell(cellNumber).value;
      const isRequired: boolean =
        dataType && dataType.validateRequired && headerName.indexOf('*') >= 0;
      const cellValue: any = cell.formula
        ? cell.result
        : excelConfig.listColumns.includes(cellNumber) &&
          cell.value &&
          cell.value.indexOf('-') >= 0
        ? cell.value.split('-')[0].trim()
        : cell.value;

      if (
        dataType &&
        ((dataType.isFinancial && cellValue) || !dataType.isFinancial)
      ) {
        const item: any = {
          address: cell.address,
          cell: cellNumber,
          row: row.number,
          value: cellValue,
        };

        // sales / revenue / cci
        if (dataType.isFinancial) {
          item['timeId'] =
            cellNumber - dataType.from === 24
              ? (applicationDate.StartTimeId + 24).toString()
              : (cfyStartTimeId + (cellNumber - dataType.from)).toString();
          // metadata
        } else {
          item['headerName'] = headerName.replace('*', '');
          item['isRequired'] = isRequired;
        }

        record[dataType.name].push(item);
      }
    });

    return record;
  }

  private saveSpeculativesFromExcel(records: Array<Record<string, any>>) {
    this.appMessagesService.show(
      MessageTemplates.SpeculativeUploadFileStarted,
      { centered: true }
    );

    this.speculativeGridService
      .getS3PresignedUrl({ contentType: 'application/json', expires: 60 })
      .then(
        (x1: any) => {
          const s3Payload: Record<string, any> = {
            customer: {
              id: this.selectedFilters.customer.MasterCustomerNumber,
              name: this.selectedFilters.customer.MasterCustomerName,
            },
            records,
          };

          this.speculativeGridService
            .uploadPayloadToS3(x1.presignedUrl, s3Payload, x1.contentType)
            .then(
              (x2: any) => {
                this.speculativeGridService.uploadFile({ id: x1.key }).then(
                  (x3: any) => {
                    const result: any = JSON.parse(x3.message);
                    const message: AppMessage = cloneDeep(
                      MessageTemplates.SpeculativeUploadFileSuccess
                    );
                    const body: string = message.body.pop();
                    message.body.push(`${result.ids.length} ${body}`);
                    this.appMessagesService.show(message, { centered: true });
                    this.getData();
                  },
                  (ex3: any) => {
                    if (ex3.errors) {
                      const message: AppMessage = cloneDeep(
                        MessageTemplates.SpeculativeUploadFileValidationsErrors
                      );
                      ex3.errors.forEach((e: string) => {
                        message.body.push(`• ${e}`);
                      });
                      this.appMessagesService.show(message, {
                        centered: true,
                      });
                    } else {
                      this.appMessagesService.show(
                        MessageTemplates.SpeculativeUploadFileError,
                        { centered: true }
                      );
                    }
                  }
                );
              },
              (ex2: any) => {
                this.appMessagesService.show(
                  MessageTemplates.SpeculativeUploadFileError,
                  { centered: true }
                );
              }
            );
        },
        (ex1: any) => {
          this.appMessagesService.show(
            MessageTemplates.SpeculativeUploadFileError,
            { centered: true }
          );
        }
      );
  }
}
