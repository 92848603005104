import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { HttpUtils, IHttpOptions } from 'src/app/core/utils/http.utils';
import { Observable, of, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

import { RequestPayload } from '../entities/request-payload';
import { Headers } from 'src/app/core/constants/http.constants';
import { Endpoints } from '../../constants/endpoints.constants';
import { SpeculativeModalConfig } from '../../components/speculative-modal/entities/speculative-modal-config';
import { Speculative } from '../../components/speculative-modal/entities/speculative';
import { SelectedFilters } from '../entities/filters/selected-filters';

@Injectable({
  providedIn: 'root',
})
export class SpeculativeModalService {
  private openSpeculativeModalEvent =
    new EventEmitter<SpeculativeModalConfig>();

  public get openSpeculativeModalEmitted(): EventEmitter<SpeculativeModalConfig> {
    return this.openSpeculativeModalEvent;
  }

  constructor(private http: HttpClient) {}

  getSpeculative(request: RequestPayload): Promise<Speculative> {
    const options: IHttpOptions = HttpUtils.getOptions([
      Headers.CLIENTAPPID_HEADER,
      Headers.IMPERSONATION_USER_HEADER,
      Headers.CORRELATION_ID_HEADER,
    ]);

    return this.http
      .post<Speculative>(Endpoints.speculativeDetail, request, options)
      .pipe(catchError(this.errorHandler.bind(this)))
      .toPromise();
  }

  getProjectedData(request: RequestPayload): Promise<Speculative> {
    const options: IHttpOptions = HttpUtils.getOptions([
      Headers.CLIENTAPPID_HEADER,
      Headers.IMPERSONATION_USER_HEADER,
      Headers.CORRELATION_ID_HEADER,
    ]);

    return this.http
      .post<Array<Record<string, number>>>(
        Endpoints.speculativeProjected,
        request,
        options
      )
      .pipe(
        switchMap((x: Array<Record<string, number>>) => {
          const speculative = new Speculative();
          speculative.initialize();
          speculative.projectedData = x;
          return of(speculative);
        }),
        catchError(this.errorHandler.bind(this))
      )
      .toPromise();
  }

  saveSpeculative(
    speculativeEdited: Speculative,
    selectedFilters?: SelectedFilters
  ): Promise<Record<string, string>> {
    const endPoint: string = speculativeEdited.isNew()
      ? Endpoints.speculativeCreate
      : Endpoints.speculativeUpdate;
    const body: any = {
      Data: speculativeEdited,
      MasterClientId: selectedFilters.customer.MasterCustomerNumber,
      MasterClientName: selectedFilters.customer.MasterCustomerName,
      View: selectedFilters.planView,
    };

    return this.http.post<Record<string, string>>(endPoint, body).toPromise();
  }

  private errorHandler(error: HttpErrorResponse): Observable<never> {
    return throwError(error || 'Server error (SpeculativeModalService)');
  }
}
