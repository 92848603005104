<div *ngIf="config" class="grid-toolbar">
    <div class="toolbar-left">
        <ng-container *ngFor="let c of config.controls" [ngSwitch]="c.controlType">
            <ng-container *ngSwitchCase="controlType.MultipleDropdown">
                <app-drop-down-multiple *ngIf="!c.isVisible || c.isVisible(config)" [placeholder]="c.placeholder"
                    [items]="c.items" [selectedItems]="c.selected" (itemsSelectionChangeEvent)="c.onChange($event, c)"
                    [type]="c.type" class="toolbar-dropdown mr-3">
                </app-drop-down-multiple>
            </ng-container>
            <ng-container *ngSwitchCase="controlType.Dropdown">
                <app-drop-down *ngIf="!c.isVisible || c.isVisible(config)" [items]="c.items" [selectedItem]="c.selected"
                    (itemSelectionChangeEvent)="c.onChange($event, c)" [type]="c.type" class="toolbar-dropdown mr-3">
                </app-drop-down>
            </ng-container>
            <ng-container *ngSwitchCase="controlType.Button">
                <button *ngIf="!c.isVisible || c.isVisible(config)" class="btn mr-3 {{c.cssClass}}"
                    (click)="c.onClick()" [ngbTooltip]="c.tooltip" placement="top"
                    [ngClass]="{'disabled' : c.isDisabled && c.isDisabled(config)}">{{c.text}}</button>
            </ng-container>
            <ng-container *ngSwitchCase="controlType.Icon">
                <div *ngIf="!c.isVisible || c.isVisible(config)" class="toolbar-icon-container" [ngbTooltip]="c.tooltip"
                    container="body" placement="top" [ngClass]="{'disabled' : c.isDisabled && c.isDisabled(config)}">
                    <div class="toolbar-icon mr-3 {{c.cssClass}}" (click)="c.onClick()">
                        <fa-icon [icon]="c.icon">
                        </fa-icon>
                        <span>{{c.text}}</span>
                    </div>
                </div>
            </ng-container>
            <ng-container *ngSwitchCase="controlType.Switch">
                <div *ngIf="!c.isVisible || c.isVisible(config)" class="toolbar-switch mr-3">
                    <app-switch rightText="" leftText="" [checked]="c.switchValue"
                        (switchChangeEvent)="c.onSwitch($event, c)">
                    </app-switch>
                    <span>{{c.text}}</span>
                </div>
            </ng-container>
        </ng-container>
    </div>
    <div class="toolbar-right w-25">
        <app-search-input [isSmall]="false" (searchEvent)="onSearchTextChanged($event)"
            [searchText]="config?.searchText">
        </app-search-input>
    </div>
</div>