<nav class="app-navbar">
    <div class="app-navbar-wrapper">
        <div class="app-navbar-left">
            <div class="navbar-tab" *ngFor="let t of tabs">
                <a [attr.id]="t.id" [routerLink]="[t.url]" [routerLinkActive]="['navbar-tab-active']"
                    [ngClass]="{'navbar-tab-disabled' : !t.enable}"
                    (click)="changeSelectedTab(t)">{{t.text.toUpperCase()}}</a>
            </div>
        </div>
        <div class="app-navbar-right">
            <app-timeframe-picker></app-timeframe-picker>
            <app-currency-picker></app-currency-picker>
            <app-data-refresh></app-data-refresh>
            <a class="btn btn-link btn-singOut"
              [href]="logOutPath">
              <fa-icon [icon]="faSignOutAlt">
              </fa-icon>
            </a>
        </div>
    </div>
</nav>
<nav class="app-subnavbar" *ngIf="selectedTab && selectedTab.subtabs?.length > 0">
    <div class="app-subnavbar-wrapper">
        <div class="app-subnavbar-left">
            <div class="navbar-tab" *ngFor="let t of selectedTab.subtabs">
                <a [attr.id]="t.id" [routerLink]="[selectedTab.url, t.url]"
                    [routerLinkActive]="['navbar-tab-active']">{{t.text}}</a>
            </div>
        </div>
    </div>
</nav>
