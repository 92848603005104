import Dictionary from 'src/app/core/utils/dictionary.utils';
import { ValueType } from 'src/app/shared/constants/common.constants';
import { TileDefinition } from '../../entities/tile-definition';

export const ProjectionTileConstants: Record<string, TileDefinition> = {
  Sales: new TileDefinition({
    title: {
      projection: 'Sales Projection',
      actuals: 'Sales Actuals',
    },
    subtitle: '',
    subAttrFnc: (params: Dictionary<any>) => {
      return '';
    },
    items: [
      {
        id: 'actuals',
        title: 'Actuals',
        typeFnc: (params: Dictionary<any>) => {
          return ValueType.Numeric;
        },
        isTotal: false,
        negativeStyleEnabled: true,
        positiveStyleEnabled: false,
        link: '/ieopportunity/actualsales',
      },
      {
        id: 'wins',
        title: 'Wins (this Month)',
        typeFnc: (params: Dictionary<any>) => {
          return ValueType.Numeric;
        },
        isTotal: false,
        negativeStyleEnabled: true,
        positiveStyleEnabled: false,
        link: '/ieopportunity/wins',
      },
      {
        id: 'opportunities',
        title: 'Projected Opps',
        typeFnc: (params: Dictionary<any>) => {
          return ValueType.Numeric;
        },
        isTotal: false,
        negativeStyleEnabled: true,
        positiveStyleEnabled: false,
        link: '/ieopportunity/opportunities',
      },
      {
        id: 'speculative',
        title: 'Speculative',
        typeFnc: (params: Dictionary<any>) => {
          return ValueType.Numeric;
        },
        isTotal: false,
        negativeStyleEnabled: true,
        positiveStyleEnabled: false,
        link: '/speculative',
      },
      {
        id: 'total',
        title: 'Total',
        typeFnc: (params: Dictionary<any>) => {
          return ValueType.Numeric;
        },
        isTotal: true,
        negativeStyleEnabled: true,
        positiveStyleEnabled: true,
      },
    ],
  }),
  Revenue: new TileDefinition({
    title: {
      projection: 'Revenue Projection',
      actuals: 'Revenue Actuals',
    },
    subtitle: '',
    subAttrFnc: (params: Dictionary<any>) => {
      return '';
    },
    items: [
      {
        id: 'actuals',
        title: 'Actuals',
        typeFnc: (params: Dictionary<any>) => {
          return ValueType.Numeric;
        },
        isTotal: false,
        negativeStyleEnabled: true,
        positiveStyleEnabled: false,
        link: '/backlog',
      },
      {
        id: 'backlog',
        title: 'Backlog',
        typeFnc: (params: Dictionary<any>) => {
          return ValueType.Numeric;
        },
        isTotal: false,
        negativeStyleEnabled: true,
        positiveStyleEnabled: false,
        link: '/backlog',
      },
      {
        id: 'opportunities',
        title: 'Projected Opps',
        typeFnc: (params: Dictionary<any>) => {
          return ValueType.Numeric;
        },
        isTotal: false,
        negativeStyleEnabled: true,
        positiveStyleEnabled: false,
        link: '/phasing',
      },
      {
        id: 'speculative',
        title: 'Speculative',
        typeFnc: (params: Dictionary<any>) => {
          return ValueType.Numeric;
        },
        isTotal: false,
        negativeStyleEnabled: true,
        positiveStyleEnabled: false,
        link: '/speculative',
      },
      {
        id: 'total',
        title: 'Total',
        typeFnc: (params: Dictionary<any>) => {
          return ValueType.Numeric;
        },
        isTotal: true,
        negativeStyleEnabled: true,
        positiveStyleEnabled: true,
      },
    ],
  }),
  Cci: new TileDefinition({
    title: {
      projection: 'Delivered CCI Projection',
      actuals: 'Delivered CCI Actuals',
    },
    subtitle: '',
    subAttrFnc: (params: Dictionary<any>) => {
      return params.getItem('isPercentage') ? 'percentages' : 'values';
    },
    items: [
      {
        id: 'actuals',
        title: 'Actuals',
        typeFnc: (params: Dictionary<any>) => {
          return params.getItem('isPercentage')
            ? ValueType.Percentage
            : ValueType.Numeric;
        },
        isTotal: false,
        negativeStyleEnabled: true,
        positiveStyleEnabled: false,
        link: '/backlog',
      },
      {
        id: 'backlog',
        title: 'Backlog',
        typeFnc: (params: Dictionary<any>) => {
          return params.getItem('isPercentage')
            ? ValueType.Percentage
            : ValueType.Numeric;
        },
        isTotal: false,
        negativeStyleEnabled: true,
        positiveStyleEnabled: false,
        link: '/backlog',
      },
      {
        id: 'opportunities',
        title: 'Projected Opps',
        typeFnc: (params: Dictionary<any>) => {
          return params.getItem('isPercentage')
            ? ValueType.Percentage
            : ValueType.Numeric;
        },
        isTotal: false,
        negativeStyleEnabled: true,
        positiveStyleEnabled: false,
        link: '/phasing',
      },
      {
        id: 'speculative',
        title: 'Speculative',
        typeFnc: (params: Dictionary<any>) => {
          return params.getItem('isPercentage')
            ? ValueType.Percentage
            : ValueType.Numeric;
        },
        isTotal: false,
        negativeStyleEnabled: true,
        positiveStyleEnabled: false,
        link: '/speculative',
      },
      {
        id: 'total',
        title: 'Total',
        typeFnc: (params: Dictionary<any>) => {
          return params.getItem('isPercentage')
            ? ValueType.Percentage
            : ValueType.Numeric;
        },
        isTotal: true,
        negativeStyleEnabled: true,
        positiveStyleEnabled: true,
      },
    ],
  }),
  WonCci: new TileDefinition({
    title: {
      actuals: 'Won CCI Actuals',
      projection: 'Won CCI Projection',
    },
    subtitle: '',
    subAttrFnc: (params: Dictionary<any>) => {
      return params.getItem('isPercentage') ? 'percentages' : 'values';
    },
    items: [
      {
        id: 'woncci',
        title: 'Won CCI',
        typeFnc: (params: Dictionary<any>) => {
          return params.getItem('isPercentage')
            ? ValueType.Percentage
            : ValueType.Numeric;
        },
        isTotal: false,
        negativeStyleEnabled: true,
        positiveStyleEnabled: false,
      },
      {
        id: 'opportunities',
        title: 'Projected Opps',
        typeFnc: (params: Dictionary<any>) => {
          return params.getItem('isPercentage')
            ? ValueType.Percentage
            : ValueType.Numeric;
        },
        isTotal: false,
        negativeStyleEnabled: true,
        positiveStyleEnabled: false,
        link: '/ieopportunity/opportunities',
      },
      {
        id: 'speculative',
        title: 'Speculative',
        typeFnc: (params: Dictionary<any>) => {
          return params.getItem('isPercentage')
            ? ValueType.Percentage
            : ValueType.Numeric;
        },
        isTotal: false,
        negativeStyleEnabled: true,
        positiveStyleEnabled: false,
        link: '/speculative',
      },
      {
        id: 'total',
        title: 'Total',
        typeFnc: (params: Dictionary<any>) => {
          return params.getItem('isPercentage')
            ? ValueType.Percentage
            : ValueType.Numeric;
        },
        isTotal: true,
        negativeStyleEnabled: true,
        positiveStyleEnabled: true,
      },
    ],
  }),
  SalesGCP: new TileDefinition({
    title: {
      projection: 'Sales GCP',
      actuals: 'Sales GCP',
    },
    subtitle: '',
    subAttrFnc: (params: Dictionary<any>) => {
      return '';
    },
    items: [
      {
        id: 'ActualsSales',
        title: 'Actuals',
        typeFnc: (params: Dictionary<any>) => {
          return ValueType.Numeric;
        },
        isTotal: false,
        negativeStyleEnabled: true,
        positiveStyleEnabled: false,
        link: '/ieopportunity/actualsales',
      },
      {
        id: 'OpportunitySalesWinsAmount',
        title: 'Wins (this Month)',
        typeFnc: (params: Dictionary<any>) => {
          return ValueType.Numeric;
        },
        isTotal: false,
        negativeStyleEnabled: true,
        positiveStyleEnabled: false,
        link: '/ieopportunity/wins',
      },
      {
        id: 'ProjectionOppsQualSalesAmount',
        title: 'Projected Opps',
        typeFnc: (params: Dictionary<any>) => {
          return ValueType.Numeric;
        },
        isTotal: false,
        negativeStyleEnabled: true,
        positiveStyleEnabled: false,
        link: '/ieopportunity/opportunities',
      },
      {
        id: 'ProjectionOppsUnqualSalesAmount',
        title: 'Projected Opps',
        typeFnc: (params: Dictionary<any>) => {
          return ValueType.Numeric;
        },
        isTotal: false,
        negativeStyleEnabled: true,
        positiveStyleEnabled: false,
        link: '/ieopportunity/opportunities',
      },
      {
        id: 'SpeculativeSales',
        title: 'Speculative',
        typeFnc: (params: Dictionary<any>) => {
          return ValueType.Numeric;
        },
        isTotal: false,
        negativeStyleEnabled: true,
        positiveStyleEnabled: false,
        link: '/speculative',
      },
      {
        id: 'SalesCurrentTotal',
        title: 'Total',
        typeFnc: (params: Dictionary<any>) => {
          return ValueType.Numeric;
        },
        isTotal: true,
        negativeStyleEnabled: true,
        positiveStyleEnabled: true,
      },
    ],
  }),
};

export const ProjectionTileErrorMessages = {
  Service504:
    "We couldn't retrieve the data in time. Try refreshing your browser to fix the issue.",
  ServiceDefault:
    'There was an issue during the process. Please, try again later.',
  WmuFcFilter: 'Remove Financial Customer filter before applying WMU option.',
};
