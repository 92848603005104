import { DatePipe } from '@angular/common';
import moment from 'moment';
import { minBy, sumBy } from 'lodash';

import {
  GridCellItemAlign,
  OpportunityTypes,
  ViewOptions,
} from 'src/app/shared/constants/grid.constants';
import { TextValuePair } from 'src/app/shared/services/entities/common/key-value';
import { SelectedFilters } from 'src/app/shared/services/entities/filters/selected-filters';
import { ColDef, CsvExportParams, GridOptions } from 'ag-grid';
import {
  GridToolbarConfig,
  GridToolbarDropdown,
} from 'src/app/shared/components/base/grid/components/grid-toolbar/entities/grid-toolbar-config';
import { Filters } from 'src/app/shared/services/entities/filters/filters';
import { SortColum } from 'src/app/shared/components/base/grid/entities/grid-config';
import { ActiveDate } from 'src/app/shared/services/entities/filters/active-date';
import { ActiveDates } from 'src/app/shared/constants/filters.constants';
import { MmbDate } from 'src/app/shared/services/entities/filters/date';
import { DateUtils } from 'src/app/core/utils/date.utils';
import { GridCellIcon } from 'src/app/shared/components/base/grid/entities/grid-cell-icon';

import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';

export const ComponentNames = {
  opportunities: 'opportunities',
  types: 'oppType',
  targets: 'target',
  export: 'export',
  expand: 'expand',
  compress: 'compress',
  save: 'save',
  reset: 'reset',
  noActivity: 'noActivity',
  msaIndicator: 'msaIndicator',
  fullyWeighted: 'fullyWeighted',
};

export const GridFilters = {
  Types: [
    new TextValuePair({
      text: OpportunityTypes.All,
      value: OpportunityTypes.All,
    }),
    new TextValuePair({
      text: OpportunityTypes.Qualified,
      value: OpportunityTypes.Qualified,
    }),
    new TextValuePair({
      text: OpportunityTypes.Unqualified,
      value: OpportunityTypes.Unqualified,
    }),
  ],
  TargetOptions: (selectedFilters: SelectedFilters) => {
    return [
      new TextValuePair({
        text: 'Projection',
        value: ViewOptions.Current,
      }),
      new TextValuePair({
        text: selectedFilters.projection.getText(),
        value: ViewOptions.Projection,
      }),
      new TextValuePair({
        text: `Projection vs ${selectedFilters.projection.getText()} Variance`,
        value: ViewOptions.CompareProjection,
      }),
    ];
  },
};

export const GridColDefs = {
  getColDefs: (
    toolbarConfig: GridToolbarConfig,
    hasAccountPlan: boolean,
    isReadOnlyMode: boolean,
    isAdmin: boolean,
    cellIcons?: Array<GridCellIcon>
  ): Array<ColDef> => {
    const targetsDropdown: GridToolbarDropdown = toolbarConfig.findControl(
      ComponentNames.targets
    );

    return [
      {
        headerName: 'OPPORTUNITY ID',
        colId: 'opportunityId',
        field: 'OpportunityLink',
        type: 'linkColumn',
        minWidth: 180,
        width: 180,
        pinned: true,
        sort: GridColDefs.Shared.paramSort('opportunityId'),
        valueGetter: (params: any) => {
          return params.data.Id && params.data.Name
            ? params.data.Id
            : undefined;
        },
        pinnedRowCellRendererParams: { forceTotals: true },
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true,
        checkboxSelection: (params: any) => {
          return (
            params.data.Id &&
            params.data.hasDifferences() &&
            params.data.IsCompleteFinancials
          );
        },
        cellRendererParams: (params: any) => {
          let icons: Array<GridCellIcon> = [];

          if (cellIcons) {
            icons = cellIcons.filter(
              (x: GridCellIcon) =>
                !x.showIconByRow || x.showIconByRow(params.data)
            );
          }

          return {
            icons,
          };
        },
        comparator: (valueA: string, valueB: string): number => {
          return valueA.localeCompare(valueB, 'en-US', { numeric: true });
        },
      },
      {
        headerName: 'OPPORTUNITY NAME',
        colId: 'opportunityName',
        field: 'OpportunityLink',
        type: 'linkColumn',
        minWidth: 250,
        width: 300,
        pinned: true,
        sort: GridColDefs.Shared.paramSort('opportunityName'),
        valueGetter: (params: any) => {
          return params.data.Id && params.data.Name
            ? params.data.Name
            : undefined;
        },
        comparator: (valueA: string, valueB: string): number => {
          return valueA.localeCompare(valueB, 'en-US', { numeric: false });
        },
      },
      {
        headerName: 'DIRECTOR',
        colId: 'director',
        field: 'Director',
        type: 'textColumn',
        minWidth: 100,
        pinned: true,
        sort: GridColDefs.Shared.paramSort('director'),
      },
      {
        headerName: 'STAGE',
        field: 'Stage',
        colId: 'stage',
        type: 'textColumn',
        minWidth: 80,
        width: 90,
        pinned: true,
        sort: GridColDefs.Shared.paramSort('stage'),
      },
      {
        headerName: 'LAST REFRESHED',
        field: 'UpdatedDate',
        colId: 'updatedDate',
        type: 'dateColumn',
        minWidth: 95,
        pinned: false,
        sort: GridColDefs.Shared.paramSort('updatedDate'),
      },
      {
        headerName: 'SIGN DATE',
        colId: 'signDate',
        field: 'StatusSinceDate',
        type: 'dateColumn',
        minWidth: 90,
        pinned: false,
        sort: GridColDefs.Shared.paramSort('signDate'),
      },
      {
        headerName: 'START DATE',
        field: 'StartDate',
        colId: 'startDate',
        type: 'dateColumn',
        minWidth: 90,
        pinned: false,
        sort: GridColDefs.Shared.paramSort('startDate'),
      },
      {
        headerName: 'END DATE',
        field: 'EndDate',
        colId: 'endDate',
        type: 'dateColumn',
        minWidth: 90,
        pinned: false,
        sort: GridColDefs.Shared.paramSort('endDate'),
      },
      {
        headerName: 'FULL LIFE UNWEIGHTED SALES',
        field: 'TotalRevenueAmount',
        colId: 'totalSales',
        type: 'numberColumn',
        minWidth: 90,
        pinned: false,
        sort: GridColDefs.Shared.paramSort('totalSales'),
        aggFunc: 'sum',
      },
      {
        headerName: 'FULL LIFE CCI%',
        field: 'TotalCciPercentage.cciPercent',
        colId: 'cciPercentage',
        type: 'percentColumn',
        minWidth: 80,
        pinned: false,
        sort: GridColDefs.Shared.paramSort('cciPercentage'),
        aggFunc:
          targetsDropdown.selected.value === ViewOptions.CompareProjection
            ? GridColDefs.Shared.sumCciPercentageCompare
            : GridColDefs.Shared.sumCci,
      },
      {
        headerName: 'PROJECTED SALES',
        field: 'ProjectedRevenueAmount',
        colId: 'projectedSales',
        type: 'numberColumn',
        minWidth: 100,
        pinned: false,
        aggFunc: 'sum',
        sortedAt: 10,
        sort: GridColDefs.Shared.paramSort('projectedSales'),
        headerTooltip: 'Projected Sales within selected timeframe',
      },
      {
        headerName: 'PROJECTED REVENUE',
        field: 'NetRevenue',
        colId: 'netRevenue',
        type: 'numberColumn',
        minWidth: 100,
        pinned: false,
        aggFunc: 'sum',
        sortedAt: 10,
        sort: GridColDefs.Shared.paramSort('netRevenue'),
        headerTooltip: 'Projected Revenue within selected timeframe',
      },
      {
        headerName: 'PROJECTED CCI$',
        field: 'Cci',
        colId: 'projectedCCI',
        type: 'numberColumn',
        minWidth: 100,
        pinned: false,
        aggFunc: 'sum',
        sortedAt: 10,
        sort: GridColDefs.Shared.paramSort('projectedCCI'),
        headerTooltip: 'Projected CCI$ within selected timeframe',
      },
      {
        headerName: 'PROJECTED CCI%',
        field: 'CciPercent.cciPercent',
        colId: 'projectedCCIPer',
        type: 'percentColumn',
        minWidth: 100,
        pinned: false,
        aggFunc: GridColDefs.Shared.sumCciPercentage,
        sortedAt: 10,
        sort: GridColDefs.Shared.paramSort('projectedCCIPer'),
        headerTooltip: 'Projected CCI% within selected timeframe',
      },
      {
        headerName: 'WIN PROBABILITY %',
        field: 'WinProbability',
        colId: 'winProbability',
        type: 'percentColumn',
        minWidth: 100,
        pinned: false,
        sort: GridColDefs.Shared.paramSort('winProbability'),
        pinnedRowCellRendererParams: {
          showTotalDash: true,
          align: GridCellItemAlign.Right,
        },
      },
      {
        headerName: 'PROJECTION %',
        field: 'ProjectionPct',
        colId: 'projectionPercentage',
        type: 'percentColumn',
        minWidth: 100,
        pinned: false,
        sort: GridColDefs.Shared.paramSort('projectionPercentage'),
        editable: (params: any) => {
          return (
            params.data.IsCompleteFinancials &&
            ((hasAccountPlan && !isReadOnlyMode) || isAdmin) &&
            targetsDropdown.selected.value === ViewOptions.Current
          );
        },
        valueParser: (params: any) => {
          return params.newValue ? Number(params.newValue) : 0;
        },
        cellRendererParams: (params: any) => {
          return {
            cellClass: {
              editable:
                params.data.IsCompleteFinancials &&
                ((hasAccountPlan && !isReadOnlyMode) || isAdmin) &&
                targetsDropdown.selected.value === ViewOptions.Current,
              edited:
                params.node.originalRowData &&
                !(params.value < 0 || params.value > 100)
                  ? params.node.originalRowData[
                      params.colDef.field
                    ].toString() !== params.value.toString()
                  : false,
              invalid: params.value < 0 || params.value > 100,
            },
          };
        },
        pinnedRowCellRendererParams: {
          showTotalDash: true,
          align: GridCellItemAlign.Right,
        },
      },
      {
        headerName: 'UPDATED',
        colId: 'edited',
        type: 'iconColumn',
        sort: GridColDefs.Shared.paramSort('edited'),
        sortedAt: 0,
        minWidth: 70,
        width: 70,
        pinned: false,
        filterValueGetter: (params: any) => {
          return params.data.IsCompleteFinancials &&
            params.data.hasDifferences()
            ? 'Yes'
            : 'No';
        },
        headerValueGetter: (params: any) => {
          return '<i class="material-icons">error</i>';
        },
        valueGetter: (params: any) => {
          return params.data.IsCompleteFinancials &&
            params.data.hasDifferences()
            ? (faExclamationCircle as IconProp)
            : null;
        },
      },
    ] as Array<ColDef>;
  },
  Shared: {
    paramSort: (colId: string): string => {
      const sortColumsConfig: SortColum[] = [
        { colId: 'projectedSales', sort: 'desc' },
        { colId: 'edited', sort: 'desc' },
      ];
      const sortColum = sortColumsConfig.find(
        (x: SortColum) => x.colId === colId
      );
      return sortColum ? sortColum.sort : '';
    },
    getOppDateId: (filters: Filters, date: string): number => {
      let startTimeId: number;

      if (date && date.length > 0) {
        const zero: MmbDate = minBy(filters.dates, 'Id');
        const dateFormat = new DatePipe('en-US');
        const dateAux: string = dateFormat.transform(
          DateUtils.normalizeDateString(date),
          'MM/dd/yyyy'
        );
        const splitDate: Array<string> = dateAux.split('/');
        const year: string = splitDate[2];
        const month = Number(splitDate[0]);

        const monthDiff: number = moment([year, month - 1, 1]).diff(
          moment([zero.CalendarYearNbr, zero.MonthNbr - 1, 1]),
          'months',
          true
        );
        startTimeId = zero.Id + monthDiff;
      }

      return startTimeId;
    },
    sumCci: (values: Array<number>, colDef?: ColDef): number => {
      let result = 0;

      if (colDef) {
        const totalCciAmount: number = sumBy(values, 'TotalCciPercentage.cci');
        const totalRevenueAmount: number = sumBy(
          values,
          'TotalCciPercentage.revenue'
        );

        if (typeof (totalCciAmount || totalRevenueAmount) === 'number') {
          result = (totalCciAmount / totalRevenueAmount || 0) * 100;
        }
      }

      return isFinite(result) ? result : 0;
    },
    sumCciPercentage: (values: Array<number>, colDef?: ColDef): number => {
      let result = 0;

      if (colDef) {
        const totalCciAmount: number = sumBy(values, 'CciPercent.cci');
        const totalRevenueAmount: number = sumBy(values, 'CciPercent.revenue');

        if (typeof (totalCciAmount || totalRevenueAmount) === 'number') {
          result = (totalCciAmount / totalRevenueAmount || 0) * 100;
        }
      }

      return isFinite(result) ? result : 0;
    },
    sumCciPercentageCompare: (
      values: Array<number>,
      colDef?: ColDef
    ): number => {
      let result = 0;

      if (colDef) {
        const currentCci: number = sumBy(
          values,
          'TotalCciPercentage.currentCci'
        );
        const currentRevenue: number = sumBy(
          values,
          'TotalCciPercentage.currentRevenue'
        );
        const targetCci: number = sumBy(values, 'TotalCciPercentage.targetCci');
        const targetRevenue: number = sumBy(
          values,
          'TotalCciPercentage.targetRevenue'
        );

        if (
          typeof (
            currentCci ||
            currentRevenue ||
            targetCci ||
            targetRevenue
          ) === 'number'
        ) {
          let currentPercentage: number =
            currentCci / Math.abs(currentRevenue) || 0;
          let targetPercentage: number =
            targetCci / Math.abs(targetRevenue) || 0;
          currentPercentage = isFinite(currentPercentage)
            ? currentPercentage
            : 0;
          targetPercentage = isFinite(targetPercentage) ? targetPercentage : 0;

          result = (currentPercentage - targetPercentage) * 100;
        }
      }

      return result;
    },
  },
  getExtraGridOptions: (filters: Filters): GridOptions => {
    const appDate: ActiveDate = filters.activeDates.find(
      (x: ActiveDate) => x.DateTypeCd === ActiveDates.ApplicationDate
    );

    return {
      defaultExportParams: {
        sheetName: 'Opportunities',
        fileName: 'CBP Opportunities',
      } as CsvExportParams,
      rowClassRules: {
        'incomplete-stage': (params: any) => {
          return params.data.Id && !params.data.IsCompleteFinancials;
        },
        'opp-elapsed-dates': (params: any) => {
          return (
            GridColDefs.Shared.getOppDateId(
              filters,
              params.data.StatusSinceDate
            ) < appDate.StartTimeId ||
            GridColDefs.Shared.getOppDateId(filters, params.data.StartDate) <
              appDate.StartTimeId
          );
        },
      },
    } as GridOptions;
  },
};
