import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { Headers } from 'src/app/core/constants/http.constants';
import { HttpUtils, IHttpOptions } from 'src/app/core/utils/http.utils';
import { Endpoints } from '../../constants/endpoints.constants';
import { MetricType } from '../../constants/metrics.constants';
import { RequestPayload } from '../entities/request-payload';
import { ITileResponse } from '../../components/tiles/entities/itile-response';
import { TextValuePair } from '../entities/common/key-value';

@Injectable({
  providedIn: 'root',
})
export class GilcTileService {
  private metricChangedObservable = new Subject<TextValuePair>();

  public get metricChanged(): Subject<TextValuePair> {
    return this.metricChangedObservable;
  }

  constructor(private http: HttpClient) {}

  getTileDataByType<T extends ITileResponse>(
    type: MetricType,
    request: RequestPayload
  ): Promise<T> {
    const uri: string = this.getUrlByMetricType(type);

    const options: IHttpOptions = HttpUtils.getOptions([
      Headers.CLIENTAPPID_HEADER,
      Headers.IMPERSONATION_USER_HEADER,
      Headers.CORRELATION_ID_HEADER,
    ]);

    return this.http
      .post<T>(uri, request, options)
      .pipe(catchError(this.errorHandler.bind(this)))
      .toPromise();
  }

  private getUrlByMetricType(type: MetricType): string {
    switch (type) {
      case MetricType.Sales:
        return `${Endpoints.tile}?type=SalesYoY`;
      case MetricType.Revenue:
        return `${Endpoints.tile}?type=RevenueYoY`;
      case MetricType.Cci:
        return `${Endpoints.tile}?type=CciYoY`;
      default:
        return '';
    }
  }

  private errorHandler(error: HttpErrorResponse): Observable<never> {
    return throwError(error || 'Server error (GilcTileService)');
  }
}
