import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { interval, Observable, of, throwError } from 'rxjs';
import {
  catchError,
  concatMap,
  first,
  switchMap,
  timeout,
} from 'rxjs/operators';
import { saveAs } from 'file-saver';

import { Headers } from 'src/app/core/constants/http.constants';
import {
  HttpUtils,
  IHttpOptions,
  IStrictRequestOptions,
} from 'src/app/core/utils/http.utils';
import { Endpoints } from '../constants/endpoints.constants';
import { FileExportRequestPayload } from './entities/file-export-request-payload';
import { FileExporterStatusResponse } from './entities/file-exporter-status';
import {
  FileExporterStatus,
  FileExporterType,
} from '../constants/file-exporter.constants';

@Injectable({
  providedIn: 'root',
})
export class FileExporterService {
  private timeInterval = 5000;
  private timeoutDuration: number = 5 * 60000;
  private fileType =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

  constructor(private http: HttpClient) {}

  export(
    fileExporterType: FileExporterType,
    payload: FileExportRequestPayload | Record<string, any>,
    fileName: string
  ): Promise<boolean> {
    return this.dispatch(fileExporterType, payload)
      .pipe(
        switchMap((x: FileExporterStatusResponse) => {
          return interval(this.timeInterval).pipe(
            concatMap(() => this.getStatus(x.id)),
            first((x: FileExporterStatusResponse) => {
              switch (x.status) {
                case FileExporterStatus.ERROR:
                  throw Error(x.message);
                case FileExporterStatus.DONE:
                  return true;
                default:
                  return false;
              }
            })
          );
        }),
        switchMap((x: FileExporterStatusResponse) => {
          return this.download(x.id, fileName);
        }),
        timeout(this.timeoutDuration),
        catchError(this.errorHandler.bind(this))
      )
      .toPromise();
  }

  private dispatch(
    fileExporterType: FileExporterType,
    payload: FileExportRequestPayload | Record<string, any>
  ): Observable<FileExporterStatusResponse> {
    const options: IHttpOptions = HttpUtils.getOptions([
      Headers.CLIENTAPPID_HEADER,
      Headers.IMPERSONATION_USER_HEADER,
      Headers.CORRELATION_ID_HEADER,
    ]);

    const uri = `${Endpoints.fileExporterDispatch}${fileExporterType}`;
    return this.http.post<FileExporterStatusResponse>(uri, payload, options);
  }

  private getStatus(id: string): Observable<FileExporterStatusResponse> {
    const uri = `${Endpoints.fileExporterStatus}${id}`;
    return this.http.get<FileExporterStatusResponse>(uri);
  }

  private download(id: string, fileName: string): Observable<boolean> {
    const uri = `${Endpoints.fileExporterDownload}${id}`;

    return this.http
      .get<any>(uri, {
        responseType: 'blob',
        headers: new HttpHeaders({
          Accept: this.fileType,
        }),
      } as IStrictRequestOptions)
      .pipe(
        switchMap((x: any) => this.saveToFileSystem(x, fileName)),
        catchError(this.errorHandler.bind(this))
      );
  }

  private saveToFileSystem(
    response: any,
    fileName: string
  ): Observable<boolean> {
    try {
      const blob = new Blob([response], {
        type: this.fileType,
      });
      saveAs(blob, fileName);
    } catch {
      return of(false);
    }

    return of(true);
  }

  private errorHandler(error: HttpErrorResponse): Observable<never> {
    return throwError(error || 'Server error (FileExporterService)');
  }
}
