<div class="target-tile card">
    <div class="target-tile-content card-body">
        <div class="target-tile-header">
            <div class="target-tile-title">
                <app-drop-down [items]="metrics" [selectedItem]="selectedMetric"
                    (itemSelectionChangeEvent)="onChangeMetric($event)"></app-drop-down>
            </div>
            <div class="target-tile-switch">
                <app-switch *ngIf="targetsChartConfig?.allowPercentage"
                    (switchChangeEvent)="onValueTypeSwitchChanged($event)"
                    [checked]="!targetsChartConfig?.isPercentage">
                </app-switch>
            </div>
        </div>
        <div *ngIf="!hasErrors">
            <app-targets-chart [onConfigChangedObs]="configChangeEvent.asObservable()"></app-targets-chart>
        </div>
        <div *ngIf="loaded && !hasErrors" class="target-tile-footer">
            <button class="btn btn-link link-table" (click)="onOpenTrendModal()">TABLE VIEW</button>
        </div>
        <div *ngIf="loaded && hasErrors" class="tile-error text-center">
            <span class="tile-error-header">Sorry!</span>
            <span class="tile-error-body d-block">{{errorMessage}}</span>
        </div>
        <app-spinner name="targets-tile-{{id}}" [showSpinner]="!loaded" [fullScreen]="false" spinnerColor="#3e4851">
        </app-spinner>
    </div>
</div>