import { assign, every } from 'lodash';
import { Periods } from 'src/app/shared/constants/filters.constants';
import { MetricsOptions } from 'src/app/shared/constants/grid.constants';

class PhasingResponseItemSchema {
  Id: string;
  Name: string;
  Stage: string;
  OpportunityLink: string;
  CciPercent: Record<string, number>;
  CciPercentCurrent: Record<string, number>;
  CciPercentTarget: Record<string, number>;
  ProjectionPercentage: number;
  UpdatedDate: Date;
  UpdatedBy: string;
  PhasingUpdatedDate: string;
  PhasingUpdatedBy: string;
  fullLifeRevenue: number;
  fullLifeRevenueCurrent: number;
  fullLifeRevenueTarget: number;
  fullLifeCci: number;
  fullLifeCciCurrent: number;
  fullLifeCciTarget: number;
  fullLifeCciPercent: Record<string, number>;
  fullLifeCciPercentCurrent: Record<string, number>;
  fullLifeCciPercentTarget: Record<string, number>;
  NetRevenue: number;
  Cci: number;
  NetRevenueCurrent: number;
  CciCurrent: number;
  NetRevenueTarget: number;
  CciTarget: number;
  MonthlyData: Array<PhasingPeriodResponse>;
  QuarterlyData: Array<PhasingPeriodResponse>;
  IsCompleteFinancials: boolean;
  NetRevenuePhasingIndicator: string;
  CciPhasingIndicator: string;
  PhasingSource: string;
  statusSinceDate: Date;
  StartDate: Date;
  EndDate: Date;
  MsaIndicator?: boolean;
}

export class PhasingResponseItem extends PhasingResponseItemSchema {
  constructor(filterAttributes?: PhasingResponseItemSchema) {
    super();
    assign(this, filterAttributes);
  }

  isInactive(metric: string, period: string): boolean {
    const attrName: string =
      period === Periods.Month.id ? 'MonthlyData' : 'QuarterlyData';

    switch (metric) {
      case MetricsOptions.Revenue:
        return this.isRevenueInactive(attrName);
      case MetricsOptions.Cci:
        return this.isCciInactive(attrName);
      case MetricsOptions.CciPercentage:
        return this.isCciPercentageInactive(attrName);
    }

    return false;
  }

  private isRevenueInactive(attrName: string): boolean {
    const hasAllDataZero: boolean = every(
      (this as any)[attrName],
      (x: PhasingPeriodResponse) => {
        return x.NetRevenue === 0;
      }
    );
    return (
      (this.NetRevenue === 0 || !this.NetRevenue) &&
      (this.fullLifeRevenue === 0 || !this.fullLifeRevenue) &&
      hasAllDataZero
    );
  }

  private isCciInactive(attrName: string): boolean {
    const hasAllDataZero: boolean = every(
      (this as any)[attrName],
      (x: PhasingPeriodResponse) => {
        return x.Cci === 0;
      }
    );
    return (
      (this.Cci === 0 || !this.Cci) &&
      (this.fullLifeCci === 0 || !this.fullLifeCci) &&
      hasAllDataZero
    );
  }

  private isCciPercentageInactive(attrName: string): boolean {
    const hasAllDataZero: boolean = every(
      (this as any)[attrName],
      (x: PhasingPeriodResponse) => {
        return x.CciPercent.cciPercent === 0;
      }
    );
    return (
      (this.CciPercent.cciPercent === 0 || !this.CciPercent.cciPercent) &&
      (this.fullLifeCciPercent.cciPercent === 0 ||
        !this.fullLifeCciPercent.cciPercent) &&
      hasAllDataZero
    );
  }

  isUnqualfied() {
    return this.Stage === '0A';
  }

  isQualified() {
    return this.Stage !== '0A';
  }
}

class PhasingPeriodResponseSchema {
  Id: number;
  NetRevenue: number;
  Cci: number;
  NetRevenueCurrent?: number;
  CciCurrent?: number;
  NetRevenueTarget?: number;
  CciTarget?: number;
  CciPercent?: Record<string, number>;
  CciPercentCurrent?: Record<string, number>;
  CciPercentTarget?: Record<string, number>;
  fullLifeRevenue?: number;
  fullLifeRevenueCurrent?: number;
  fullLifeRevenueTarget?: number;
  fullLifeCci?: number;
  fullLifeCciCurrent?: number;
  fullLifeCciTarget?: number;
  fullLifeCciPercent?: Record<string, number>;
  fullLifeCciPercentCurrent?: Record<string, number>;
  fullLifeCciPercentTarget?: Record<string, number>;
  FiscalYearNbr?: number;
}

export class PhasingPeriodResponse extends PhasingPeriodResponseSchema {
  constructor(filterAttributes?: PhasingPeriodResponseSchema) {
    super();
    assign(this, filterAttributes);
  }
}
