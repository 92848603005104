import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { isNumber } from 'lodash';

import { GilcCciResponse } from '../../entities/gilc-cci-response';
import { GilcTileComponent } from '../../gilc-tile.component';
import { MetricType } from 'src/app/shared/constants/metrics.constants';
import { RequestPayload } from 'src/app/shared/services/entities/request-payload';
import { GilcTileConstants } from '../../constants/gilc-tile.constants';
import { TileUtils } from '../../../utils/tile.utils';

import { FiltersService } from 'src/app/shared/services/filters.service';
import { GilcTileService } from 'src/app/shared/services/tiles/gilc-tile.service';
import { ErrorHandlerService } from 'src/app/core/services/error-handler.service';

@Component({
  selector: 'app-gilc-cci-tile',
  templateUrl: '../../gilc-tile.component.html',
  styleUrls: ['../../gilc-tile.component.scss'],
})
export class CciGilcTileComponent extends GilcTileComponent implements OnInit {
  constructor(
    private gilcTileService: GilcTileService,
    private filterService: FiltersService,
    private errorHandlerService: ErrorHandlerService,
    private changeDetector: ChangeDetectorRef
  ) {
    super(
      gilcTileService,
      filterService,
      errorHandlerService,
      changeDetector,
      MetricType.Cci
    );
  }

  ngOnInit(): void {
    super.setTempTitle(GilcTileConstants.Cci.title.common);
    this.symbol = 'bps';
    super.initializeComponent((x: RequestPayload) => {
      super.loadStarted();
      super.clearTile();
      super.setTempItems(GilcTileConstants.Cci.items);
      this.gilcTileService
        .getTileDataByType<GilcCciResponse>(this.type, x)
        .then((y: GilcCciResponse) => {
          if (isNumber(y)) {
            throw new HttpErrorResponse({
              status: y,
            });
          }

          this.gilcTile = TileUtils.getItems<GilcCciResponse>(
            GilcTileConstants.Cci,
            y
          );
        })
        .catch((error: HttpErrorResponse) => {
          super.handleTileError(error);
        })
        .finally(() => {
          super.loadCompleted();
        });
    });
  }
}
