import { sortBy } from 'lodash';
import { CalcUtils } from 'src/app/core/utils/calc.utils';
import {
  GridToolbarConfig,
  GridToolbarDropdown,
} from 'src/app/shared/components/base/grid/components/grid-toolbar/entities/grid-toolbar-config';
import { Periods } from 'src/app/shared/constants/filters.constants';
import { ViewOptions } from 'src/app/shared/constants/grid.constants';
import { MmbDate } from 'src/app/shared/services/entities/filters/date';
import { Filters } from 'src/app/shared/services/entities/filters/filters';
import {
  PhasingPeriodResponse,
  PhasingResponseItem,
} from 'src/app/shared/services/entities/grids/phasing-response';
import { ComponentNames } from '../constants/phasing-grid.constants';

export class PhasingGridUtils {
  static mapData(
    source: Array<PhasingResponseItem>,
    filters: Filters,
    toolbarConfig: GridToolbarConfig,
    isCustom: boolean
  ): Array<Record<string, any>> {
    const periodsDropdown: GridToolbarDropdown = toolbarConfig.findControl(
      ComponentNames.periods
    );
    const targetsDropdown: GridToolbarDropdown = toolbarConfig.findControl(
      ComponentNames.targets
    );

    return source.map((x: PhasingResponseItem) => {
      let row: Record<string, any> = {
        Id: x.Id,
        Name: x.Name,
        Stage: x.Stage,
        OpportunityLink: x.OpportunityLink,
        PhasingSource: x.PhasingSource,
        IsCompleteFinancials: x.IsCompleteFinancials,
        Indicator: this.getIndicator(x),
        MonthlyData: x.MonthlyData,
        StatusSinceDate: x.statusSinceDate,
        StartDate: x.StartDate,
        Total: {
          NetRevenue: x.NetRevenue,
          Cci: x.Cci,
          CciPercent: x.CciPercent,
          NetRevenueCurrent: x.NetRevenueCurrent,
          CciCurrent: x.CciCurrent,
          CciPercentCurrent: x.CciPercentCurrent,
          NetRevenueTarget: x.NetRevenueTarget,
          CciTarget: x.CciTarget,
          CciPercentTarget: x.CciPercentTarget,
        },
        TotalProj: {
          NetRevenue: x.fullLifeRevenue,
          NetRevenueCurrent: x.fullLifeRevenueCurrent,
          NetRevenueTarget: x.fullLifeRevenueTarget,
          Cci: x.fullLifeCci,
          CciCurrent: x.fullLifeCciCurrent,
          CciTarget: x.fullLifeCciTarget,
          CciPercent: x.fullLifeCciPercent,
          CciPercentCurrent: x.fullLifeCciPercentCurrent,
          CciPercentTarget: x.fullLifeCciPercentTarget,
        },
      };

      if (periodsDropdown.selected.value === Periods.Quarter.id) {
        x.QuarterlyData = this.getDataByQuarters(
          x,
          targetsDropdown.selected.value,
          filters.dates
        );
        row = this.mapQuarterlyData(row, x, isCustom);
      } else {
        row = this.mapMonthlyData(row, x, filters.dates);
      }

      return row;
    });
  }

  private static mapMonthlyData(
    row: Record<string, any>,
    source: PhasingResponseItem,
    dates: Array<MmbDate>
  ): Record<string, any> {
    source.MonthlyData.forEach((x: PhasingPeriodResponse) => {
      const period: MmbDate = dates.find((y: MmbDate) => y.Id === x.Id);
      row = {
        ...row,
        [period.Id]: x,
      };
    });

    return row;
  }

  private static mapQuarterlyData(
    row: Record<string, any>,
    source: PhasingResponseItem,
    isCustom: boolean
  ): Record<string, any> {
    source.QuarterlyData.forEach((x: PhasingPeriodResponse) => {
      const quarter: string = isCustom
        ? `q${x.Id}'${x.FiscalYearNbr.toString().slice(-2)}`
        : `q${x.Id}`;
      row = {
        ...row,
        [quarter]: x,
      };
    });

    return row;
  }

  private static getDataByQuarters(
    data: PhasingResponseItem,
    target: string,
    dates: Array<MmbDate>
  ): Array<PhasingPeriodResponse> {
    const result: Array<PhasingPeriodResponse> = [];

    data.MonthlyData.forEach((x: PhasingPeriodResponse) => {
      const quarterDate: MmbDate = dates.find((y: MmbDate) => x.Id === y.Id);
      const quarterExist = result.findIndex(
        (y: PhasingPeriodResponse) => y.Id === quarterDate.FiscalQuarterNbr
      );

      if (quarterExist >= 0) {
        result[quarterExist].NetRevenueCurrent = CalcUtils.sumValues(
          result[quarterExist].NetRevenueCurrent,
          x.NetRevenueCurrent
        );
        result[quarterExist].CciCurrent = CalcUtils.sumValues(
          result[quarterExist].CciCurrent,
          x.CciCurrent
        );
        result[quarterExist].CciPercentCurrent = this.calculatePercentageValues(
          result[quarterExist].CciPercentCurrent,
          x.CciPercentCurrent,
          false
        );

        result[quarterExist].NetRevenueTarget = CalcUtils.sumValues(
          result[quarterExist].NetRevenueTarget,
          x.NetRevenueTarget
        );
        result[quarterExist].CciTarget = CalcUtils.sumValues(
          result[quarterExist].CciTarget,
          x.CciTarget
        );
        result[quarterExist].CciPercentTarget = this.calculatePercentageValues(
          result[quarterExist].CciPercentTarget,
          x.CciPercentTarget,
          false
        );

        result[quarterExist].NetRevenue = CalcUtils.sumValues(
          result[quarterExist].NetRevenue,
          x.NetRevenue
        );
        result[quarterExist].Cci = CalcUtils.sumValues(
          result[quarterExist].Cci,
          x.Cci
        );

        if (target === ViewOptions.CompareProjection) {
          result[quarterExist].CciPercent = this.calculatePercentageValues(
            result[quarterExist].CciPercent,
            x.CciPercent,
            true
          );
        } else {
          result[quarterExist].CciPercent = this.calculatePercentageValues(
            result[quarterExist].CciPercent,
            x.CciPercent,
            false
          );
        }
      } else {
        result.push(
          new PhasingPeriodResponse({
            Id: quarterDate.FiscalQuarterNbr,
            NetRevenue: x.NetRevenue,
            Cci: x.Cci,
            CciPercent: x.CciPercent,
            NetRevenueCurrent: x.NetRevenueCurrent,
            CciCurrent: x.CciCurrent,
            CciPercentCurrent: x.CciPercentCurrent,
            NetRevenueTarget: x.NetRevenueTarget,
            CciTarget: x.CciTarget,
            CciPercentTarget: x.CciPercentTarget,
            FiscalYearNbr: quarterDate.FiscalYearNbr,
          })
        );
      }
    });

    return sortBy(result, 'Id');
  }

  private static getIndicator(opportunity: PhasingResponseItem): string {
    let indicator = '';

    const revenueIndicator: string =
      opportunity.NetRevenuePhasingIndicator.split('-')[0];
    const cciIndicator: string = opportunity.CciPhasingIndicator.split('-')[0];

    if (revenueIndicator === cciIndicator) {
      indicator = revenueIndicator;
    } else if (opportunity.PhasingSource === 'CBP') {
      indicator = 'adjusted';
    } else if (opportunity.PhasingSource === 'MMS') {
      indicator = 'overwritten';
    }

    return indicator;
  }

  private static calculatePercentageValues(
    total: Record<string, any>,
    value: Record<string, any>,
    isCompare?: boolean
  ): Record<string, any> {
    if (isCompare) {
      total.cciCurrent = CalcUtils.sumValues(
        total.cciCurrent,
        value.cciCurrent
      );
      total.revenueCurrent = CalcUtils.sumValues(
        total.revenueCurrent,
        value.revenueCurrent
      );
      total.cciTarget = CalcUtils.sumValues(total.cciTarget, value.cciTarget);
      total.revenueTarget = CalcUtils.sumValues(
        total.revenueTarget,
        value.revenueTarget
      );
      total.cciPercent =
        (CalcUtils.sumValues(total.cciCurrent, total.revenueCurrent) || 0) -
        (CalcUtils.calculateCustomPercentage(
          total.cciTarget,
          total.revenueTarget
        ) || 0);
    } else {
      total.cci = CalcUtils.sumValues(total.cci, value.cci);
      total.revenue = CalcUtils.sumValues(total.revenue, value.revenue);
      total.cciPercent = CalcUtils.calculateCustomPercentage(
        total.cci,
        total.revenue
      );
    }

    return total;
  }
}
