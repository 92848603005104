import { ChangeDetectorRef, Component } from '@angular/core';
import { Subject, Subscription } from 'rxjs';

import { MetricType } from 'src/app/shared/constants/metrics.constants';
import { AppStateService } from 'src/app/shared/services/app-state.service';
import { AppState } from 'src/app/shared/services/entities/app-state/app-state';

@Component({
  selector: 'app-opportunities',
  templateUrl: './opportunities.component.html',
  styleUrls: ['./opportunities.component.scss'],
})
export class OpportunitiesComponent {
  openTrendModalEmittedEvent = new Subject<boolean>();
  isGridExpanded: boolean;

  subscription = new Subscription();
  showGcpComponents: boolean;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private appStateService: AppStateService
  ) {}

  ngOnInit(): void {
    this.subscription.add(
      this.appStateService.appStateChanged.subscribe((x: AppState) => {
        this.showGcpComponents = x.showGcpComponents;
      })
    );
  }

  public get metricType(): typeof MetricType {
    return MetricType;
  }

  onOpenTrendModalEmitted(isChartView: boolean, type: MetricType): void {
    this.openTrendModalEmittedEvent.next(isChartView);
  }

  onExpandChanged(isExpanded: boolean): void {
    this.isGridExpanded = isExpanded;
    this.changeDetectorRef.detectChanges();
  }
}
