<app-grid-toolbar [config]="gridToolbarConfig" (searchEvent)="onSearchTextChanged($event)"></app-grid-toolbar>
<app-grid [config]="gridConfig"></app-grid>
<div class="grid-legend fixed-bottom">
    <div class="legend-item ml-3">Default Sorting:</div>
    <div class="legend-item ml-3 overwritten">
        <fa-icon [icon]="faCircle"></fa-icon>Linear Phasing overwriting previously Adjusted Phasing
    </div>
    <div class="legend-item ml-3 adjusted">
        <fa-icon [icon]="faCircle"></fa-icon>Adjusted Phasing
    </div>
    <div class="legend-item ml-3 blank">
        <fa-icon [icon]="faCircle"></fa-icon>Linear Phasing
    </div>
</div>