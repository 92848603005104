import { assign, every, isUndefined } from 'lodash';
import { Periods } from 'src/app/shared/constants/filters.constants';
import { MetricsOptions } from 'src/app/shared/constants/grid.constants';

import { ReasonCode } from '../reason-code';

class SpeculativeResponseItemSchema {
  id: string;
  name: string;
  masterCustomerNumber: string;
  masterCustomerName: string;
  reasonCode: ReasonCode;
  financialCustomerNumber: string;
  responsible: string;
  updatedBy?: string;
  status: string;
  lastUpdated: Date;
  startDate: string;
  endDate: string;
  currency: string;
  onHoldByElapsedTime?: boolean;
  sales: number;
  wonCCI: number;
  wonCCIPercentage: number;
  netRevenue: number;
  deliveredCCIDollar: number;
  deliveredCCIPercentage: Record<string, number>;
  totalSales: number;
  totalCCI: number;
  fullLifeSales: number;
  fullLifeRevenue: number;
  fullLifeCCI: number;
  fullLifeCCIPercentage: Record<string, number>;
  currentSales?: number;
  currentWonCCI?: number;
  currentWonCCIPercentage?: number;
  currentNetRevenue?: number;
  currentDeliveredCCIDollar?: number;
  currentDeliveredCCIPercentage?: Record<string, number>;
  currentTotalSales?: number;
  currentTotalCCI?: number;
  currentFullLifeSales: number;
  currentFullLifeRevenue: number;
  currentFullLifeCCI: number;
  currentFullLifeCCIPercentage: Record<string, number>;
  targetSales?: number;
  targetWonCCI?: number;
  targetWonCCIPercentage?: number;
  targetNetRevenue?: number;
  targetDeliveredCCIDollar?: number;
  targetDeliveredCCIPercentage?: Record<string, number>;
  targetTotalSales?: number;
  targetTotalCCI?: number;
  targetFullLifeSales: number;
  targetFullLifeRevenue: number;
  targetFullLifeCCI: number;
  targetFullLifeCCIPercentage: Record<string, number>;
  phasingMonthly: Array<SpeculativePhasingPeriod>;
  phasingQuaterly: Array<SpeculativePhasingPeriod>;
}

export class SpeculativeResponseItem extends SpeculativeResponseItemSchema {
  constructor(filterAttributes?: SpeculativeResponseItemSchema) {
    super();
    assign(this, filterAttributes);
  }

  isInactive(metric: string, period: string): boolean {
    const attrName: string =
      period === Periods.Month.id ? 'phasingMonthly' : 'quarterlyData';

    switch (metric) {
      case MetricsOptions.Summary:
        return this.isSummaryInactive();
      case MetricsOptions.Sales:
        return this.isSalesInactive(attrName);
      case MetricsOptions.Revenue:
        return this.isRevenueInactive(attrName);
      case MetricsOptions.Cci:
        return this.isCciInactive(attrName);
      case MetricsOptions.CciPercentage:
        return this.isCciPercentageInactive(attrName);
    }

    return false;
  }

  private isSalesInactive(attrName: string): boolean {
    const hasAllDataZero: boolean = every(
      (this as any)[attrName],
      (x: SpeculativePhasingPeriod) => {
        return x.sales === 0 || !x.sales;
      }
    );
    return (
      ((this.fullLifeSales === 0 || !this.fullLifeSales) && hasAllDataZero) ||
      this.status === 'On Hold'
    );
  }

  private isRevenueInactive(attrName: string): boolean {
    const hasAllDataZero: boolean = every(
      (this as any)[attrName],
      (x: SpeculativePhasingPeriod) => {
        return x.revenue === 0 || !x.revenue;
      }
    );
    return (
      ((this.fullLifeRevenue === 0 || !this.fullLifeRevenue) &&
        hasAllDataZero) ||
      this.status === 'On Hold'
    );
  }

  private isCciInactive(attrName: string): boolean {
    const hasAllDataZero: boolean = every(
      (this as any)[attrName],
      (x: SpeculativePhasingPeriod) => {
        return x.cci === 0 || !x.cci;
      }
    );
    return (
      ((this.fullLifeCCI === 0 || !this.fullLifeCCI) && hasAllDataZero) ||
      this.status === 'On Hold'
    );
  }

  private isCciPercentageInactive(attrName: string): boolean {
    const hasAllDataZero: boolean = every(
      (this as any)[attrName],
      (x: SpeculativePhasingPeriod) => {
        return (
          (x.cciPercentage as Record<string, number>).cciPercent === 0 ||
          !(x.cciPercentage as Record<string, number>).cciPercent
        );
      }
    );
    return (
      ((this.fullLifeCCIPercentage.cciPercent === 0 ||
        !this.fullLifeCCIPercentage.cciPercent) &&
        hasAllDataZero) ||
      this.status === 'On Hold'
    );
  }

  private isSummaryInactive(): boolean {
    return (
      ((this.fullLifeSales === 0 || !this.fullLifeSales) &&
        (this.fullLifeRevenue === 0 || !this.fullLifeRevenue) &&
        (this.fullLifeCCI === 0 || !this.fullLifeCCI) &&
        (this.fullLifeCCIPercentage.cciPercent === 0 ||
          !this.fullLifeCCIPercentage.cciPercent)) ||
      this.status === 'On Hold'
    );
  }
}

class SpeculativePhasingPeriodSchema {
  timeId: number;
  sales: number;
  revenue: number;
  cci: number;
  cciPercentage: Record<string, number> | number;
  currentSales?: number;
  currentRevenue?: number;
  currentCci?: number;
  currentCciPercentage?: Record<string, number> | number;
  targetSales?: number;
  targetRevenue?: number;
  targetCci?: number;
  targetCciPercentage?: Record<string, number> | number;
  fiscalYear?: number;
}

export class SpeculativePhasingPeriod extends SpeculativePhasingPeriodSchema {
  constructor(filterAttributes?: SpeculativePhasingPeriodSchema) {
    super();
    assign(this, filterAttributes);
  }

  hasFinancials(): boolean {
    return (
      (this.sales !== 0 && !isUndefined(this.sales)) ||
      (this.revenue !== 0 && !isUndefined(this.revenue)) ||
      (this.cci !== 0 && !isUndefined(this.cci)) ||
      (this.cciPercentage !== 0 && !isUndefined(this.cciPercentage))
    );
  }
}
